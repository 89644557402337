<script setup lang="ts">
import { VideoStepUpload } from '@/generated/graphql'
import { computed, onUnmounted, ref, watch } from 'vue'

type LocalVideoStepUpload = Omit<VideoStepUpload, 'id' | 'upload'>

const props = defineProps<{
  src: string
  options: LocalVideoStepUpload
}>()
const emit = defineEmits<{
  durationInMillis: [value: number]
}>()

const video = ref<HTMLVideoElement>()
const isPlaying = ref(false)
const isLoading = ref(true)
const isSeeking = ref(false)

const currentTimeInMillis = ref(0)
let currentTimeInterval: number
const unmonitorCurrentTime = () => {
  clearInterval(currentTimeInterval)
}
onUnmounted(unmonitorCurrentTime)

const durationInMillis = ref<number>()
watch(durationInMillis, (v) => {
  if (v) {
    emit('durationInMillis', v)
  }
})

const bufferValue = ref<number>(0)
const onBuffered = (buffered?: TimeRanges) => {
  const _duration = durationInMillis.value
  if (!buffered || !_duration) {
    return
  }

  const current = currentTimeInMillis.value / 1000
  for (let i = 0; i < buffered.length; i++) {
    if (buffered.start(i) <= current && buffered.end(i) >= current) {
      bufferValue.value = buffered.end(i) / (_duration / 1000)
      return
    }
  }
}

const isAtEnd = computed(() => {
  const end = props.options.fragmentEndInMillis || durationInMillis.value || Infinity

  return currentTimeInMillis.value >= end
})

watch(video, (v) => {
  if (!v) {
    return
  }

  v.onplay = () => {
    isPlaying.value = true
  }
  v.onpause = () => (isPlaying.value = false)
  v.onloadstart = () => (isLoading.value = true)
  v.oncanplay = () => (isLoading.value = false)
  v.onseeking = () => (isSeeking.value = true)
  v.onseeked = () => (isSeeking.value = false)
  v.ondurationchange = () => {
    if (video.value?.duration) {
      durationInMillis.value = video.value?.duration * 1000
    }
  }

  unmonitorCurrentTime()
  currentTimeInterval = setInterval(() => {
    onBuffered(video.value?.buffered)
    currentTimeInMillis.value = (video.value?.currentTime || 0) * 1000
  }, 100)
})

const onReset = (toTimeInMillis?: number) => {
  if (!video.value) {
    return
  }
  const time = toTimeInMillis || props.options.fragmentStartInMillis || 0

  video.value.currentTime = time / 1000
}
watch(
  () => props.src,
  () => {
    onReset()
  },
)

watch(
  () => [props.options, currentTimeInMillis, video],
  () => {
    const v = video.value
    if (!v || isSeeking.value) {
      return
    }

    const start = props.options.fragmentStartInMillis || 0
    const end = props.options.fragmentEndInMillis || Infinity
    const loop = props.options.fragmentLoop
    const time = currentTimeInMillis.value
    const duration = durationInMillis.value

    if (time < start) {
      v.currentTime = start / 1000
      return
    }
    if (time > end || time == duration) {
      if (loop) {
        onReset()
        v.play()
      } else {
        v.pause()
      }
    }
  },
  { deep: true, immediate: true },
)

const onPlay = () => {
  if (isPlaying.value) {
    video.value?.pause()
  } else {
    if (isAtEnd.value) {
      onReset()
    }
    video.value?.play()
  }
}

const isFirstPause = ref(true)
watch(isPlaying, (v) => {
  if (v) {
    isFirstPause.value = false
  }
})

const onIntersection = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio < 0.2 && isPlaying.value) {
      video.value?.pause()
    }
  })
}
const intersectionObserver = new IntersectionObserver(onIntersection, {})
watch(video, (v) => {
  intersectionObserver.disconnect()
  if (v) {
    intersectionObserver.observe(v)
  }
})

const visibilityListener = () => {
  if (document.hidden && isPlaying.value) {
    video.value?.pause()
  }
}
document.addEventListener('visibilitychange', visibilityListener)

onUnmounted(() => {
  intersectionObserver.disconnect()
  document.removeEventListener('visibilitychange', visibilityListener)
})

defineOptions({ inheritAttrs: false })
</script>

<template>
  <div style="position: relative">
    <v-btn
      v-if="isFirstPause"
      density="compact"
      variant="plain"
      icon
      size="80"
      :loading="isLoading"
      class="pauseOverlay"
      @click="onPlay"
    >
      <v-icon size="80">play_circle_filled</v-icon>
    </v-btn>
    <video
      ref="video"
      v-bind="$attrs"
      :src="props.src"
      crossorigin="anonymous"
      disablepictureinpicture
      preload="metadata"
      @click="onPlay"
    />
  </div>

  <v-slider
    style="margin-top: -7px"
    :model-value="currentTimeInMillis"
    :min="props.options.fragmentStartInMillis || 0"
    :max="props.options.fragmentEndInMillis || durationInMillis || Infinity"
    density="compact"
    hide-details
    track-size="7"
    track-color="#f6f6f6"
    track-fill-color="secondary"
    thumb-size="0"
    @update:model-value="onReset"
  />

  <div class="text-center">
    <v-btn-group>
      <v-btn density="compact" icon size="40" :loading="isLoading" @click="onPlay">
        <v-icon size="40">{{ isPlaying ? 'pause_circle_filled' : 'play_circle_filled' }}</v-icon>
      </v-btn>
    </v-btn-group>
  </div>

  <div class="imageCaption">{{ props.options.caption }}</div>
</template>

<style scoped lang="scss">
video {
  border: 1px solid #ccc;
}
.progress {
  margin-top: -7px;
  z-index: 100;
}
.pauseOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  opacity: 0.8;
}
.imageCaption {
  text-align: center;
  font-size: 0.95em;
}
</style>
