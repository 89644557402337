<script setup lang="ts">
import {
  UpdateVideoStepUpload,
  UpdateVideoStepUploadDataQuery,
  UpdateVideoStepUploadDataQueryVariables,
  UpdateVideoStepUploadMutation,
  UpdateVideoStepUploadMutationVariables,
} from '@/generated/graphql'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { mockUpdateVideoStepUpload } from '@/generated/graphql-mocks'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import FormVideoStepUpload from '@/components/stepUpload/FormVideoStepUpload.vue'

const props = defineProps<{
  id: string
}>()
const emit = defineEmits<{
  updated: []
}>()

type Model = Partial<UpdateVideoStepUpload>
const model = reactive<Model>({})
const modelKeys = Object.keys(mockUpdateVideoStepUpload())

type LocalStepUpload = UpdateVideoStepUploadDataQuery['product']['videoStepUpload']
const fetchQuery = useQuery<
  UpdateVideoStepUploadDataQuery,
  UpdateVideoStepUploadDataQueryVariables
>(
  gql`
    query UpdateVideoStepUploadData($id: ID!) {
      product {
        videoStepUpload(id: $id) {
          id
          caption
          fragmentStartInMillis
          fragmentEndInMillis
          fragmentLoop
          upload {
            id
          }
        }
      }
    }
  `,
  () => ({ id: props.id }),
)
const uploadId = computed(() => fetchQuery.result.value?.product.videoStepUpload.upload.id)

const mutation = useMutation<UpdateVideoStepUploadMutation, UpdateVideoStepUploadMutationVariables>(
  gql`
    mutation UpdateVideoStepUpload($command: UpdateVideoStepUpload!) {
      product {
        updateVideoStepUpload(command: $command) {
          id
        }
      }
    }
  `,
)
const dialog = ref<ComponentExposed<typeof UpsertDialog>>()
const form = ref<ComponentExposed<typeof FormVideoStepUpload>>()
const open = async () => {
  const data = await fetchQuery.refetch({ id: props.id })
  const v = data?.data?.product.videoStepUpload as LocalStepUpload
  modelKeys.forEach((k) => {
    ;(model as Record<string, unknown>)[k as keyof Model] = v[k as keyof LocalStepUpload]
  })
  dialog.value?.open(() =>
    mutation.mutate({ command: model as UpdateVideoStepUpload }).then(() => {
      emit('updated')
    }),
  )
}
defineExpose({ open })
</script>

<template>
  <upsert-dialog ref="dialog" :validation="form?.validation" type="update">
    <form-video-step-upload v-if="uploadId" ref="form" v-model="model" :upload-id="uploadId" />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
