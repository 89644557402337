<script setup lang="ts">
import { computed } from 'vue'
import { Move, Placement } from '@/generated/graphql'

const props = defineProps<{
  group: string
  id: string
  isLast?: boolean
}>()
const emit = defineEmits<{
  dropped: [id: string, beforeId: string]
  moved: [value: Move]
}>()

const classGroup = computed(() => `drop-group-${props.group}`)
const classDropContainer = 'drop-container'
const classDropHover = 'drop-hover'
const classDragging = 'drop-dragging'

function resetDragHover() {
  document.querySelectorAll(`.${classDropHover}`).forEach((e) => e.classList.remove(classDropHover))
}

function dragStart(e: DragEvent) {
  e.dataTransfer!.effectAllowed = 'move'
  e.dataTransfer!.dropEffect = 'move'
  e.dataTransfer!.setData('nodeId', props.id)
  document.querySelectorAll(`.${classGroup.value}`).forEach((e) => e.classList.add(classDragging))
}

function dragEnd() {
  document
    .querySelectorAll(`.${classGroup.value}`)
    .forEach((e) => e.classList.remove(classDragging))
}

function dragEnter(e: DragEvent) {
  const target = e.target as HTMLElement
  if (!target.classList.contains(classDropContainer)) {
    return
  }

  target.classList.add(classDropHover)
}

function dragLeave(e: DragEvent) {
  const target = e.target as HTMLElement
  if (!target.classList.contains(classDropContainer)) {
    return
  }

  target.classList.remove(classDropHover)
}

function dropBefore(e: DragEvent) {
  resetDragHover()
  const draggedNodeId = e.dataTransfer?.getData('nodeId') as string

  emit('moved', { ids: [draggedNodeId], otherId: props.id, placement: Placement.Above })
}
function dropAfter(e: DragEvent) {
  resetDragHover()
  const draggedNodeId = e.dataTransfer?.getData('nodeId') as string

  emit('moved', { ids: [draggedNodeId], otherId: props.id, placement: Placement.Below })
}
</script>

<template>
  <div
    :class="`${classDropContainer} ${classGroup} drop-before`"
    @dragenter.prevent="dragEnter"
    @dragover.prevent
    @dragleave.prevent="dragLeave"
    @drop="dropBefore"
  />
  <span draggable="true" @dragstart="(e) => dragStart(e)" @dragend="dragEnd()">
    <slot name="default">
      <v-icon icon="drag_indicator" class="drag-handle" />
    </slot>
  </span>
  <div
    v-if="props.isLast"
    :class="`${classDropContainer} ${classGroup} drop-after`"
    @dragenter.prevent="dragEnter"
    @dragover.prevent
    @dragleave.prevent="dragLeave"
    @drop="dropAfter"
  />
</template>

<style scoped lang="scss">
.drop-container {
  position: absolute;
  height: 50%;
  width: 100%;
  z-index: 99;
  display: none;

  * {
    pointer-events: none;
  }
}
.drop-container.drop-dragging {
  display: block;
}
.drop-container.drop-before {
  top: -25%;
  left: 0;
}
.drop-container.drop-after {
  bottom: 0%;
  left: 0;
}
.drop-container.drop-hover {
  border: #555 dashed 2px;
}
.drag-handle {
  z-index: 110;
  cursor: grab;
}
</style>
