import { Locale } from '@/translations/main'

export const enLocale: Locale = {
  button: {
    save: 'Save',
    ok: 'Ok',
    cancel: 'Cancel',
    close: 'Close',
    add: 'Add',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    copy: 'Copy',
    next: 'Next',
    previous: 'Previous',
    discard: 'Discard',
  },
  notification: {
    failed: 'Something went wrong',
    saved: 'Saved',
    deleted: 'Deleted',
    duplicated: 'Duplicated',
    deleteTitle: 'Delete {name}?',
    deleteWarningTitle: 'Be careful',
    deleteHindranceText: 'I understand that deleting this item is dangerous and irreversible',
  },
  language: {
    NL: 'Dutch',
    EN: 'English',
  },
  navigationDrawer: {
    platform: 'Platform',
    organization: 'Organization',
    currentUploads: 'Current uploads',
  },
  section: {
    processEditor: 'Process Editor',
    operations: 'Operations',
    checks: 'Checks',
    issues: 'Issues',
  },
  view: {
    home: {
      welcome: 'Welcome!',
      selectOrganization: 'Select an organization to get started',
      buttonGo: 'Go',
    },
    organization: {
      bops: {
        buttonCreateEmpty: 'Create empty BOP',
        buttonDuplicate: 'Duplicate BOP',
        cannotCreateMessage:
          'No new version can be created as long as the last version is not released.',
        releaseTooltip: 'Release this version',
      },
      bopProcess: {
        downloadMbomButton: 'Download MBOM',
        menuView: 'Go to module',
        menuAddChild: 'Add next',
        menuAddParent: 'Link to previous',
        menuUpdateNode: 'Edit module details',
        menuDeleteParent: 'Unlink from previous',
        menuDeleteNode: 'Delete module',
        menuMoveAbove: 'Move above',
      },
      home: {
        productDescription: 'Define the Bills of Process for your products.',
        operatorTitle: 'Operator home',
        operatorDescription: 'View instructions to create a module or create a production batch.',
        issueDescription: 'Report a new issue or look at current issues reported by others.',
        productionBatchDescription:
          'View past production batches and find the as-built status of your products.',
      },
      module: {
        downloadMbomButton: 'Download MBOM',
      },
      operatorProduct: {
        title: 'Products',
        titleBatchesInProgress: 'Work in progress',
        noData: 'No products found',
      },
      operatorProductConfiguration: {
        noData: 'No configurations found',
        buttonCreateProductionBatch: 'Produce batch',
        buttonViewModule: 'View',
      },
    },
  },
  component: {
    accountMenu: {
      switchOrganization: 'Switch organization',
      logOut: 'Log out',
    },
    agreeToTerms: {
      title: 'Agree to the Terms and Conditions',
      text: 'To use Baseboard, you need to agree to the <a href="https://baseboard.pro/terms-and-conditions" target="_blank">Terms and Conditions</a>.',
      labelReadCheck: "I've read and understood the Terms and Conditions",
      buttonAgree: 'I agree to the Terms and Conditions',
    },
    apiErrorSnackbar: {
      error: {
        unknown: {
          title: 'Something went wrong',
          message:
            'An unknown error has occurred. You can retry if you want. These error are reported to us so we are aware and we will take action if needed. If this keeps happening or it is urgent feel free to reach out to our support desk.',
        },
        apiUnreachable: {
          title: 'API unreachable',
          message:
            "We're having trouble reaching our API. Sit tight, this probably won't take long.",
        },
        privileges: {
          title: 'Missing privileges',
          message:
            "You don't have the right privileges to perform this action. That's on us, we shouldn't show you things that you do not have access to. If you do need access, ask you organization admin to give them to you.",
        },
      },
      actionMoreDetails: 'Details',
      actionDismiss: 'Dismiss',
      detailsTitle: 'Error details',
      detailsClose: 'Close',
      detailsCopy: 'Copy',
    },
    bopNavigationDrawer: {
      headerTimePerModule: 'Time spent per module',
      headerAssociatedEboms: 'Associated EBOMs',
      headerAssociatedIssues: 'Associated unresolved issues',
    },
    bopSelector: {
      buttonModules: 'Go to BOP',
      buttonBOPs: 'List BOPs',
    },
    bopReleaseConfirmation: {
      title: 'Release BOP',
      message:
        "You're about to release the BOP version {bopVersion} for product {productName} with product configuration {productConfigurationName} at site {siteName}. This is an irreversible process that makes this release read-only and will have downstream effects like the creation of article numbers for modules. Are you sure?",
      confirmation: 'I understand this is an irreversible process and understand the consequences',
      cancelButton: 'Cancel',
      releaseButton: 'Release BOP',
    },
    bulkAllocationSuggestionActions: {
      createTitle: 'Create allocation suggestions',
      createButton: 'Create',
      createConfirmation: 'Allocation suggestions created',
      acceptTitle: 'Accept {count} matching allocation suggestion(s)',
      acceptButton: 'Accept',
      acceptConfirmation: 'Matching allocation suggestions accepted',
      rejectTitle: 'Reject {count} undecided allocations suggestion(s)',
      rejectButton: 'Reject',
      rejectConfirmation: 'Undecided suggestions rejected',
    },
    confirmDialog: {
      title: 'Are you sure?',
      ok: 'OK',
      cancel: 'Cancel',
    },
    completeProductionBatch: {
      title: 'Complete production batch',
      text: 'Are you sure you want to complete the production batch for production order number {poNumber} with serial number(s) {serialNumbers}?',
      incompleteChecks: 'There are incomplete checks in the following steps:',
      buttonActivator: 'Complete',
      buttonCancel: 'Cancel',
      buttonSave: 'Complete',
    },
    createIssueDialog: {
      title: 'Create an issue',
      linkToSerialNumbers: 'Link this issue to these serial numbers',
    },
    createStep: {
      title: 'Create step',
    },
    createStepUploads: {
      title: 'Add attachments to step',
    },
    csvEbomLoader: {
      headerEbom: 'EBOM',
      headerColumns: 'Columns',
      instructionColumns: 'Please provide the header names for the following columns',
      ebomName: "What's the name of this EBOM?",
      encoding: 'How is the file encoded? (UTF-8 is a safe default)',
      rootArticleNumber: 'What is the root article number',
      columnArticleNumber: 'Article number ',
      columnArticleName: 'Article name',
      columnQuantity: 'Quantity',
      columnArticleRevision: 'Revision',
      columnUnit: 'Unit',
      columnParentArticleNumber: 'Parent article number',
      buttonProcess: 'Process CSV',
    },
    dataTable: {
      loading: 'Loading...',
      noData: 'No data',
      page: `{'{'}0{'}'}-{'{'}1{'}'} of {'{'}2{'}'}`,
      itemsPerPage: 'Items per page',
      moreData:
        "Showing the first {count} results. Adjust the filters if you can't find what you're looking for.",
    },
    deleteDialog: {
      title: 'Delete {name}?',
      areYouSure: 'Are you sure you want to delete {name}?',
      delete: 'Delete',
      cancel: 'Cancel',
      warningTitle: 'Be careful',
      hindranceText: 'I understand that deleting this item is dangerous and irreversible',
      deleteText: 'Are you sure you want to delete {name}?',
    },
    downloadMbomDialog: {
      title: 'Download MBOM',
      closeButton: 'Close',
      downloadBopMbom: 'Download BOP MBOM',
    },
    downloadModuleMbomButton: {
      labelShort: 'Download MBOM',
      labelLong: 'Download MBOM for {name}',
    },
    duplicateModule: {
      title: 'Duplicate module',
    },
    duplicateStep: {
      title: 'Duplicate step',
    },
    ebomBulkAllocateDialog: {
      title: 'Bulk allocate EBOM parts',
      text: 'Select parts and allocate them to a step by dragging them there. Click to select or deselect one part; Click one part and Shift+Click another part to select all intermediate parts (within one assembly); Ctrl+Click / Cmd+Click an assembly to select all underlying parts; Right-Click to deselect all selected parts.',
    },
    ebomDiffDialog: {
      title: 'Differences between {oldEbomName} and {newEbomName}',
      selectLabel: 'Select EBOM to compare',
      diffButton: 'Show differences',
      closeButton: 'Close',
    },
    ebomDiffSameParts: {
      title: '{count} allocated part(s) overlapping',
      swapExplanation:
        'These EBOM parts are exactly the same in EBOM {oldEbomName} and {newEbomName}. You can now easily swap the allocations from the EBOM Parts in {oldEbomName} to EBOM Parts in {newEbomName}.',
      swapButton: 'Swap',
      notificationSwapped:
        'The overlapping EBOM part allocations have been swapped to the new EBOM parts',
    },
    ebomDiffTree: {
      noDifferences: 'No differences found',
    },
    ebomLoader: {
      buttonActivate: 'Load EBOM',
      buttonCancel: 'Cancel',
      titlePickEbom: 'Pick EBOM',
      titleLoadEbom: 'Load EBOM',
      titlePromoteEbomParts: 'Promote parts',
      titleEbomPartAllocationSuggestions: 'Part allocation suggestions',
      titleDone: 'Done',
      loadEbomFromCsv: 'From CSV',
      doneHeader: 'Done!',
      doneMessage: 'The EBOM is processed.',
      doneButton: 'Close',
    },
    ebomPartAllocationSuggestionsLoader: {
      header: 'Suggest part allocations',
      message:
        'If you previously allocated EBOM parts, we can make suggestions on where to put the current EBOM Parts. You can always trigger these suggestions later in the EBOM view. ',
      skipButton: 'Skip',
      createSuggestionsButton: 'Suggest allocations',
      successMessage: 'Created {count} suggestions',
    },
    ebomPartDetails: {
      buttonPromoteEbomPart: 'Promote',
      quantityAllocatedToSteps: 'Quantity allocated to steps',
      headerStepAllocations: 'Step Allocations',
      tableHeaderModule: 'Module',
      tableHeaderStep: 'Step',
      tableHeaderQuantity: 'Quantity',
      promoteDialogTitle: 'Promote to article?',
      promoteDialogContent: 'Are you sure you want to promote this to an article?',
      labelSuggestion: 'Suggestion',
    },
    ebomPartTree: {
      title: 'Parts',
      filterOpenSuggestionsHint: 'Only show parts with open suggestions',
      filterAllocationDisparityHint: 'Only show parts with an allocation disparity',
    },
    editDialog: {
      save: 'Save',
      cancel: 'Cancel',
    },
    helpDialog: {
      title: 'Help is near',
      text: 'We have a few channels for you to get help: <ul><li>You can report a bug or request a feature <a href="https://lindie.app/share/f1981f18ef85e8cc559868c99519f17567c82647" target="_blank">here</a>.</li><li>You can talk to us directly through <a href="https://baseboard-workspace.slack.com" target="_blank">our Slack</a> (you should have a private channel there)</li><li>And of course there\'s always good old email: support&#64;baseboard.pro</li></ul>',
    },
    issueCommentList: {
      buttonResolve: 'Resolve',
      buttonUnresolve: 'Unresolve',
    },
    iconArchived: {
      archived: 'Archived',
      notArchived: 'Not archived',
    },
    iconConsumable: {
      title: 'Consumable',
    },
    iconEbom: {
      title: 'EBOM',
    },
    iconIssue: {
      title: 'Issue',
    },
    iconManual: {
      title: 'Manual',
    },
    iconPart: {
      title: 'Part',
    },
    iconProductionBatch: {
      title: 'Production batch',
    },
    mainAppBar: {
      buttonOrgHome: 'Organization home',
      buttonOperator: 'Operator home',
    },
    operatorCurrentProductionBatch: {
      title: 'Currently working on production order number {poNumber}',
      deselectButton: 'Deselect',
    },
    operatorModuleStepper: {
      finalizeTitle: 'Finalize',
      doneTitle: 'Done',
      previousButton: 'Previous',
      nextButton: 'Next',
      issueButton: 'Report an issue',
    },
    operatorProductionBatchDrawer: {
      titleBatchesInProgress: 'Your work in progress',
    },
    operatorStepTree: {
      title: 'Steps',
    },
    pageHeader: {
      createButton: 'Create',
    },
    articleAllocationList: {
      source: 'Source',
      sourceEbom: 'EBOM',
      sourceManual: 'Manual',
    },
    promoteEbomParts: {
      title: 'EBOM loaded',
      instructions:
        "Below are the parts in the EBOM that are unknown Articles. You can now choose to promote them to an Article so you can later use them. A good guideline whether to promote an EBOM part is whether you intend it be part of the MBOM. Once you're finished, click DONE",
      buttonSelectNonAssemblies: 'Select non-assemblies',
      buttonPromote: 'Promote to article',
      buttonDone: 'Done',
      successMessage: 'Parts have been promoted to articles',
    },
    siteButtonPicker: {
      noData: 'First create a site for your organization',
    },
    stepPickerDialog: {
      title: 'Choose a step',
      select: 'Select this step',
    },
    stepProposalsConverter: {
      titleNoSteps: 'No instructions found',
      textNoSteps:
        "We couldn't find any instructions in the file you provided. You can try another file or use different settings for this one and try again.",
      titleSteps: 'Create steps',
      textSteps:
        'Below you see the steps that we could find in the the file you provided. Click on the Create button to add the step to your module along with the selected uploads. You can edit the steps and uploads later.',
    },
    stepProposalsFromFileDialog: {
      titlePickFile: 'Select file',
      titleLoad: 'Extract instructions',
      titleConvertToSteps: 'Create steps',
      explanationTitle: 'Create steps from file',
      explanationText:
        'You can select a file below and we will try to extract information so you can create steps with the least amount of effort. We support the following file types:',
      explanationMedia: 'Audio and video files with spoken instructions',
      explanationPdf: 'PDF files with text and images',
      disclaimerTitle: 'Your data might leave the EU',
      disclaimerText:
        'The selected file might leave the EU region for analysis. Other than the file you provide, data processors will not receive contextual information like your organization name. Whichever data processors we choose, we guarantee our Terms of Service apply, especially when it comes to intellectual property rights and confidentiality.',
      inputUpload: 'Pick a media file',
      buttonCreateProposals: 'Extract steps',
      errorTitle: 'Something went wrong',
      errorText:
        "It's a complicated process, sometimes things go wrong. Below you can find some details about the error. If that doesn't help, please reach out to support so we can help you.",
    },
    stepProposalsProgress: {
      optimizing: {
        title: 'Preparing',
        message: 'Optimizing media format for fast processing',
      },
      extracting: {
        title: 'Analyzing',
        message: 'Extracting the instructions. This might take several minutes, hang in there!',
      },
      clustering: {
        title: 'Processing',
        message: 'Bundling instructions into steps and removing redundant data',
      },
      done: {
        title: 'Done!',
        message: 'Step proposals are ready for review.',
      },
    },
    stepProposalsFromPdfLoader: {
      inputFirstPage: 'First page',
      inputLastPage: 'Last page',
    },
    stepProposalsFromMediaLoader: {
      inputLanguage: 'In what language are the spoken instructions?',
    },
    stepTree: {
      title: 'Steps in module',
      buttonProposeFromFile: 'Create steps from file',
      buttonCreate: 'Create step',
      buttonCopy: 'Copy step',
    },
    stepToolbar: {
      buttonDelete: 'Delete step',
    },
    textAreaField: {
      markdownDetails:
        'You can use <a href="https://github.github.com/gfm/" target="_blank">Markdown</a>, for instance to make your text <b>**bold**</b> <i>_italic_</i> or <s>~strikethrough~</s>',
    },
    uploadButton: {
      instructions: 'Select files to upload, drag & drop them, or paste them',
    },
    uploadPicker: {
      title: 'Pick a file',
      search: 'Search',
      maybeMoreItems: 'Only showing a limited number of items, refine your search to discover more',
      selectButton: 'Select',
      closeButton: 'Close',
      newUploadButton: 'Upload new file',
      tableItemsPerPage: 'Items per page',
      tableNoData: 'No uploads found',
    },
    upsertDialog: {
      title: {
        create: 'Create',
        update: 'Update',
      },
    },
    userProductionBatches: {
      buttonOtherUsers: 'Find batches assigned to other users',
      buttonTransferBatch: 'Transfer batch to you',
      confirmTransferText: 'Transfer batch with production order number {poNumber} to you?',
    },
    userPermissions: {
      title: 'Permissions',
      organizationPermissions: 'Organization permissions',
      addOrganization: 'Add organization',
      field: {
        userPermissions: 'User permissions',
      },
    },
    versionStatus: {
      status: {
        RELEASED: 'Released',
        DRAFT: 'Draft',
      },
    },
  },
  validation: {
    abbreviation: {
      regex: 'An abbreviation should be between 1 and 5 characters',
      unique: 'This abbreviation is already taken',
    },
    filename: {
      regex:
        'A filename can only contain letters, numbers, and these characters: -, _, +, .; it also needs to end in an extension of letters, numbers, and `_`',
    },
    isChecked: {
      notChecked: 'This should be checked',
    },
    maxDifference: {
      message: 'The maximum difference should be {difference}',
    },
    milliQuantity: {
      min: 'Value should be at least {min}',
      max: 'Value should be at most {max}',
    },
    normalName: {
      regex: 'A name should be between 1 and 150 characters',
    },
    normalDescription: {
      regex: 'A description should be between 0 and 255 characters',
    },
    orgSlug: {
      pattern:
        'The slug must be between 3 and 50 characters and can only contain: lowercase letters, numbers, -',
      taken: 'This slug is already taken',
    },
    unique: {
      unique: 'Should be unique and this is already taken',
    },
    userEmail: {
      taken: 'This email is already taken by another user',
    },
  },
  permission: {
    OrgAdmin: {
      name: 'Administrator',
      description:
        'Administrators have access to everything of the organization, including user and permission management.',
    },
    OrgArticleManagement: {
      name: 'Articles',
      description: 'Can create, edit and delete articles.',
    },
    OrgMbom: {
      name: 'MBOM',
      description: 'Can export MBOMs.',
    },
    OrgMember: {
      name: 'Member',
      description:
        'Is a member of the organization, needed for basic access. Can create, edit and delete their own issues; view production batches.',
    },
    OrgOperator: {
      name: 'Operator',
      description: 'Can access the operator environment',
    },
    OrgPartAllocation: {
      name: 'Part Allocation',
      description: 'Can allocate articles to steps',
    },
    OrgProductManagement: {
      name: 'Products',
      description: 'Can create, edit and delete products and product configurations',
    },
    OrgUpload: {
      name: 'Upload',
      description: 'Can upload files, for example for steps or for issues',
    },
  },
  entity: {
    article: {
      singular: 'Article',
      plural: 'Articles',
      field: {
        articleNumber: 'Article Number',
        revision: 'Revision',
        name: 'Name',
        unit: 'Unit',
        quantity: 'Quantity',
        type: 'Type',
        source: 'Source',
      },
      type: {
        PART: 'Part',
        CONSUMABLE: 'Consumable',
      },
      unit: {
        full: {
          GRAM: 'Gram',
          KILOGRAM: 'Kilogram',
          LITER: 'Liter',
          MILLILITER: 'Milliliter',
          METER: 'Meter',
          MILLIMETER: 'Millimeter',
          PIECES: 'Pieces',
        },
        abbreviated: {
          GRAM: 'GR',
          KILOGRAM: 'KG',
          LITER: 'L',
          MILLILITER: 'ML',
          METER: 'M',
          MILLIMETER: 'MM',
          PIECES: 'PCS',
        },
      },
      validation: {
        articleAndRevisionTaken: 'An article already exists with this article number and revision',
      },
      disableEditBecauseReleased: 'Cannot edit this article because it is used in a released BOP',
      disableDeleteBecauseReleased:
        'Cannot delete this article because it is used in a released BOP',
    },
    bop: {
      singular: 'Bill of Process',
      plural: 'Bills of Process',
      deleteWarning:
        'When you delete a Bill of Process, you will lose the process tree of this BOP, all Steps in that tree that are not part of other BOPs, reviews and their comments, etc. Make absolutely sure this is what you want to do.',
      field: {
        version: 'Version',
        versionStatus: 'Status',
      },
    },
    check: {
      singular: 'Check',
      plural: 'Checks',
      field: {
        poNumber: 'Production Order Number',
        serialNumber: 'Serial Number',
        reportingUser: 'Reporting User',
        released: 'Completed',
        value: 'Value',
        confirmed: 'Confirmed',
        milliQuantity: 'Quantity',
        text: 'Text',
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
    checkConfiguration: {
      singular: 'Check',
      plural: 'Checks',
      field: {
        type: 'Type',
        description: 'Description',
        lowerLimit: 'Lower limit',
        upperLimit: 'Upper limit',
        isRequired: 'Required',
      },
      type: {
        CONFIRMATION: 'Confirm',
        NUMERIC: 'Numeric value',
        STRING: 'Text value',
      },
    },
    ebom: {
      singular: 'EBOM',
      plural: 'EBOMs',
      deleteWarning:
        'When you delete this EBOM, all its EBOM Parts will be unallocated from the steps',
      field: {
        name: 'Name',
      },
    },
    ebomPart: {
      singular: 'EBOM Part',
      plural: 'EBOM Parts',
      isPromoted: 'Is promoted to Article',
      field: {
        articleNumber: 'Article number',
        articlePath: 'Path in EBOM',
        name: 'Name',
        quantity: 'Quantity',
        revision: 'Revision',
      },
    },
    ebomPartAllocation: {
      singular: 'EBOM Part Allocation',
      plural: 'EBOM Part Allocations',
    },
    issue: {
      singular: 'Issue',
      plural: 'Issues',
      field: {
        name: 'Name',
        createdAt: 'Created',
        createdByUser: 'Created by',
        updatedAt: 'Updated',
        assignedToUsers: 'Assigned to',
        description: 'Description',
        links: 'Linked to',
        resolvedAt: 'Resolved',
        resolved: 'Resolved',
        uploads: 'Attachments',
      },
      filter: {
        assignedToUser: 'Assigned to user',
        isResolved: 'Is resolved',
        poNumbers: 'Involves production order numbers (separate with `,`)',
        serialNumbers: 'Involves serial numbers (separate with `,`)',
        bopId: 'BOP',
      },
    },
    issueComment: {
      singular: 'Comment',
      plural: 'Comments',
      field: {
        createdAt: 'Created',
        createdByUser: 'Created by',
        description: 'Description',
        resolvedAt: 'Resolved',
        resolved: 'Resolved',
        uploads: 'Attachments',
        uploadIds: 'Uploads',
        updatedAt: 'Updated',
      },
    },
    graphNode: {
      singular: 'Node',
      plural: 'Nodes',
      field: {
        milliQuantity: 'Quantity',
      },
    },
    issueLink: {
      singular: 'Link',
      plural: 'Links',
      field: {
        bopId: 'BOP',
        moduleId: 'Module',
        stepId: 'Step',
        poNumber: 'Production Order Number',
        serialNumber: 'Serial Number',
        serialNumbers: 'Serial Numbers',
        articleId: 'Article',
      },
      type: {
        article: 'To article',
        bop: 'To BOP',
        module: 'To module',
        step: 'To step',
        serialNumber: 'To production order & serial number',
      },
    },
    manualPartAllocation: {
      singular: 'Manual Part Allocation',
      plural: 'Manual Part Allocations',
    },
    module: {
      singular: 'Module',
      plural: 'Modules',
      deleteWarning:
        'When you delete a Module, you delete all underlying entities like Steps, their relations to Articles, etc. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        abbreviation: 'Abbreviation',
        description: 'Description',
      },
    },
    organization: {
      singular: 'Organization',
      plural: 'Organizations',
      deleteWarning:
        'When you delete an organization, you delete ABSOLUTELY EVERYTHING belonging to it, like its Products, Product Configurations, Sites, BOPs, Checks, etc. This is IRREVERSIBLE and about the MOST DANGEROUS THING you can do on this platform. Make REALLY, ABSOLUTELY, UNEQUIVOCALLY, WITHOUT A DOUBT sure this is what you want to do.',
      field: {
        name: 'Name',
        slug: 'Slug',
      },
    },
    product: {
      singular: 'Product',
      plural: 'Products',
      deleteWarning:
        'When you delete a Product, you delete all underlying entities like Product Configurations, Bills of Process, Steps, etc. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        abbreviation: 'Abbreviation',
        description: 'Description',
        image: 'Cover image',
      },
    },
    productConfiguration: {
      singular: 'Product Configuration',
      plural: 'Product Configurations',
      deleteWarning:
        'When you delete a product configuration, you delete all underlying entities like the bill of process. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        abbreviation: 'Abbreviation',
        description: 'Description',
        image: 'Cover image',
      },
    },
    productionBatch: {
      singular: 'Production batch',
      plural: 'Production batches',
      field: {
        poNumber: 'Production order number',
        serialNumbers: 'Serial numbers',
        completed: 'Is completed',
        assignedUser: 'Assigned user',
      },
      filter: {
        poNumber: 'Production order number contains',
        serialNumbers: 'Serial number contains',
        completed: 'Is completed',
      },
    },
    site: {
      singular: 'Site',
      plural: 'Sites',
      conceptExplanation: 'Sites are pl',
      deleteWarning:
        'When you delete a Site, you delete all associated entities like Product Configurations, Bills of Process, Steps, Tooling, etc. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        abbreviation: 'Abbreviation',
        archived: 'Archived',
      },
    },
    stepType: {
      singular: 'Step Type',
      plural: 'Step Types',
      editWarning:
        'This Step Type is already used in a released BOP. If you change it, those changes will be propagated to all those BOPs. Make sure this is what you want to do. Alternatively, you can archive this Step Type and create a new one for future use.',
      disableDeleteBecause:
        'This Step Type is used in one or more released BOPs and cannot de deleted',
      deleteWarning:
        'When you delete a Step Type, you delete all associated entities like Steps and all their associated entities like Part Allocations, Check Configurations, etc. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        archived: 'Archived',
        released: 'Released',
      },
    },
    step: {
      singular: 'Step',
      plural: 'Steps',
      field: {
        name: 'Name',
        stepType: 'Type',
        durationInSeconds: 'Duration',
        instructionText: 'Instructions',
        images: 'Images',
        tools: 'Tools',
      },
    },
    stepInput: {
      singular: 'Input from Step',
      plural: 'Inputs from Steps',
      field: {
        milliQuantity: 'Quantity',
      },
    },
    stepOutput: {
      singular: 'Output of Step',
      plural: 'Outputs of Step',
      field: {
        milliQuantity: 'Quantity',
      },
    },
    stepUpload: {
      singular: 'Attachment',
      plural: 'Attachments',
      field: {
        caption: 'Caption',
        fragmentStart: 'Start at',
        fragmentEnd: 'End at',
        fragmentLoop: 'Loop',
      },
    },
    tool: {
      singular: 'Tool',
      plural: 'Tools',
      editWarning:
        'This Tool is already used in a released BOP. If you change it, those changes will be propagated to all those BOPs. Make sure this is what you want to do. Alternatively, you can archive this Tool and create a new one for future use.',
      disableDeleteBecause: 'This Tool is used in one or more released BOPs and cannot de deleted',
      deleteWarning:
        'When you delete a Tool, you delete it from all the Steps it is used in (but not the steps themselves). Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        description: 'Description',
        site: 'Site',
        images: 'Images',
        archived: 'Archived',
        released: 'Released',
      },
    },
    upload: {
      singular: 'Upload',
      plural: 'Uploads',
      field: {
        name: 'Name',
        contentType: 'Content type',
        createdAt: 'Created at',
      },
    },
    user: {
      singular: 'User',
      plural: 'Users',
      field: {
        name: 'Name',
        firstname: 'Firstname',
        lastname: 'Lastname',
        email: 'Email',
        needsPassword: 'Initialize password (SSO users will not need this)',
      },
    },
  },
}
