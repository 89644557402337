<script setup lang="ts">
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'
import { Validation } from '@vuelidate/core'
import { Ref } from 'vue'

const props = defineProps<{
  validation?: Validation | Ref<Validation>
}>()

const { openAsyncDialog, asyncDialogResolve, asyncDialogLoading, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>(props.validation)

const open = async () => {
  return openAsyncDialog()
}

defineExpose({ open })
</script>

<template>
  <v-dialog v-if="asyncDialogResolve" v-model="asyncDialogIsOpen" v-bind="$attrs" scrollable>
    <v-card>
      <v-card-title>
        <slot name="title" />

        <v-btn
          density="compact"
          icon="close"
          class="float-right"
          variant="flat"
          @click="asyncDialogResolve()"
        />
      </v-card-title>
      <v-card-text>
        <slot name="default" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot name="actions" :resolve="asyncDialogResolve" :loading="asyncDialogLoading" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
