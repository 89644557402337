<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { PermissionOrganization } from '@/generated/graphql'
import { watch } from 'vue'

const model = defineModel<PermissionOrganization[]>({ required: true })

const { t } = useI18n()

const permissions = Object.keys(PermissionOrganization).map((p, i) => ({
  name: t(`permission.${p}.name`),
  description: t(`permission.${p}.description`),
  value: Object.values(PermissionOrganization)[i],
}))

watch(model, (v, old) => {
  if (v.length < 0) {
    return
  }
  if (!v.includes(PermissionOrganization.OrgMember)) {
    model.value.push(PermissionOrganization.OrgMember)
  }
  if (
    !old.includes(PermissionOrganization.OrgAdmin) &&
    v.includes(PermissionOrganization.OrgAdmin)
  ) {
    model.value = Object.values(PermissionOrganization)
    return
  }
  if (
    v.includes(PermissionOrganization.OrgAdmin) &&
    v.length != Object.keys(PermissionOrganization).length
  ) {
    model.value = model.value.filter((p) => p != PermissionOrganization.OrgAdmin)
  }
})
</script>

<template>
  <v-list v-model:selected="model" select-strategy="leaf">
    <v-list-item
      v-for="p in permissions"
      :key="p.value"
      density="compact"
      :value="p.value"
      :disabled="p.value == PermissionOrganization.OrgMember"
    >
      <template #prepend="{ isSelected }">
        <v-list-item-action start>
          <v-checkbox-btn :model-value="isSelected" density="compact" hide-details />
        </v-list-item-action>
      </template>
      <v-list-item-title>
        {{ p.name }}
      </v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        {{ p.description }}
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>

<style scoped lang="scss"></style>
