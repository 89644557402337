<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  AddTreeGraphNode,
  CreateStep,
  CreateVideoStepUpload,
  GraphEntityType,
  StepProposalConverterStepMutation,
  StepProposalConverterStepMutationVariables,
  StepProposalResultDetailsFragment,
  VideoExtension,
  VideoStepUploadProposal,
} from '@/generated/graphql'
import ViewVideoStepUpload from '@/components/stepUpload/ViewVideoStepUpload.vue'
import { computed, reactive, ref, watch } from 'vue'
import StepTypePicker from '@/components/input/StepTypePicker.vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { v4 } from 'uuid'
import useSave from '@/composables/useSave'
import useVuelidate from '@vuelidate/core'
import { createStepValidationRules } from '@/validation/step'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

export type StepProposalConverterStepProposal = StepProposalResultDetailsFragment['proposals'][0]
type LocalVideoUploadProposal = VideoStepUploadProposal

const props = defineProps<{
  proposal: StepProposalConverterStepProposal
  productId: string
  moduleId: string
}>()
const emit = defineEmits<{
  created: []
  discard: []
}>()

const { t } = useI18n()

const asVideoExtension = (proposal: VideoStepUploadProposal): VideoExtension => {
  return proposal.upload.extension as VideoExtension
}

const tabsModel = ref()
const selectedVideoIds = ref<string[]>([])
watch(
  () => props.proposal,
  (v) => {
    tabsModel.value = v.videoUploadProposals[0]?.id
    selectedVideoIds.value = v.videoUploadProposals.map((u) => u.id)
  },
  { immediate: true },
)

type CreateModel = Partial<CreateStep>
const createModel = reactive<CreateModel>({})
const validation = useVuelidate<CreateModel>(createStepValidationRules, createModel)
watch(
  () => props.proposal,
  (v) => {
    createModel.id = v4()
    createModel.toolIds = []
    createModel.productId = props.productId
    createModel.name = v.name
    createModel.durationInSeconds = v.durationInSeconds
    createModel.instructionText = v.instructionText
    validation.value.$reset()
  },
  { immediate: true },
)

const stepMutation = useMutation<
  StepProposalConverterStepMutation,
  StepProposalConverterStepMutationVariables
>(gql`
  mutation StepProposalConverterStep($command: CreateStep!, $node: AddTreeGraphNode!) {
    product {
      createStep(command: $command) {
        id
      }
      addNodeToModuleGraph(command: $node)
    }
  }
`)
const videoMutation = useMutation(gql`
  mutation StepProposalConverterVideos($commands: [CreateVideoStepUpload!]!) {
    product {
      createVideoStepUploads(commands: $commands) {
        id
      }
    }
  }
`)
const createVideos = computed<CreateVideoStepUpload[]>(() =>
  selectedVideoIds.value.map((id): CreateVideoStepUpload => {
    const p = props.proposal.videoUploadProposals.find(
      (v) => v.id == id,
    ) as LocalVideoUploadProposal

    return {
      id: v4(),
      stepId: createModel.id as string,
      uploadId: p.upload.id,
      fragmentStartInMillis: p.fragmentStartInMillis,
      fragmentEndInMillis: p.fragmentEndInMillis,
      fragmentLoop: false,
      caption: undefined,
    }
  }),
)
const onCreated = async () => {
  const id = createModel.id as string
  const node: AddTreeGraphNode = {
    entityId: props.moduleId,
    node: {
      entityType: GraphEntityType.Step,
      entityId: id,
      parentId: undefined,
      id: v4(),
    },
  }
  await stepMutation.mutate({ command: createModel as CreateStep, node })
  await videoMutation.mutate({ commands: createVideos.value })
  emit('created')
}
const { saveButtonProps, showSavedNotification } = useSave(onCreated, validation)
</script>

<template>
  <v-card class="mb-3">
    <v-card-text>
      <v-row dense>
        <v-col cols="6">
          <h2>{{ props.proposal.name }}</h2>

          <step-type-picker
            v-model="createModel.stepTypeId"
            :label="t('entity.step.field.stepType')"
            :validation="validation.stepTypeId"
          />

          <p class="mt-3">{{ props.proposal.instructionText }}</p>
        </v-col>
        <v-col cols="6">
          <v-tabs v-if="props.proposal.videoUploadProposals.length > 0" v-model="tabsModel">
            <v-tab v-for="(u, i) in props.proposal.videoUploadProposals" :key="u.id" :value="u.id">
              <v-checkbox
                v-model="selectedVideoIds"
                :value="u.id"
                :label="`${i + 1}`"
                hide-details
              />
            </v-tab>
          </v-tabs>

          <v-tabs-window v-model="tabsModel">
            <v-tabs-window-item
              v-for="u in props.proposal.videoUploadProposals"
              :key="u.id"
              :value="u.id"
              class="pt-2"
            >
              <view-video-step-upload
                :src="asVideoExtension(u).url"
                :options="u"
                width="100%"
                height="100%"
              />
            </v-tabs-window-item>
          </v-tabs-window>
        </v-col>
      </v-row>

      <notification-saved :model-value="showSavedNotification" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="emit('discard')">
        {{ t('button.discard') }}
      </v-btn>
      <v-btn variant="elevated" color="primary" v-bind="saveButtonProps">
        {{ t('button.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss"></style>
