<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import { StepUploadListDataQueryVariables, StepUploadViewerDataQuery } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import ViewImageStepUpload from '@/components/stepUpload/ViewImageStepUpload.vue'
import ViewVideoStepUpload from '@/components/stepUpload/ViewVideoStepUpload.vue'

const props = defineProps<{
  stepId: string
  maxImageWidth: number
  maxImageHeight: number
}>()

const { t } = useI18n()

const fetchQuery = useQuery<StepUploadViewerDataQuery, StepUploadListDataQueryVariables>(
  gql`
    query StepUploadViewerData($stepId: ID!, $imageOptions: ImageOptions!) {
      product {
        step(id: $stepId) {
          uploads {
            ... on IStepUpload {
              id
              upload {
                id
                extension {
                  ... on ImageExtension {
                    url(options: $imageOptions)
                  }
                  ... on VideoExtension {
                    url
                  }
                }
              }
            }
            ... on ImageStepUpload {
              caption
            }
            ... on VideoStepUpload {
              caption
              fragmentStartInMillis
              fragmentEndInMillis
              fragmentLoop
            }
          }
        }
      }
    }
  `,
  () => ({
    stepId: props.stepId,
    imageOptions: {
      height: props.maxImageHeight,
      width: props.maxImageWidth,
    },
  }),
)
const uploads = computed(() => fetchQuery.result.value?.product.step.uploads || [])

const tabsModel = ref<string>()
watch(uploads, (v) => {
  tabsModel.value = v[0]?.id
})

type LocalUpload = StepUploadViewerDataQuery['product']['step']['uploads'][0]
type LocalImageExtension = LocalUpload['upload']['extension'] & { __typename: 'ImageExtension' }
const asImageExtension = (upload: LocalUpload): LocalImageExtension => {
  return upload.upload.extension as LocalImageExtension
}
type LocalVideoExtension = LocalUpload['upload']['extension'] & { __typename: 'VideoExtension' }
const asVideoExtension = (upload: LocalUpload): LocalVideoExtension => {
  return upload.upload.extension as LocalVideoExtension
}
</script>

<template>
  <v-tabs v-model="tabsModel" align-tabs="center">
    <v-tab v-for="(u, i) in uploads" :key="u.id" :value="u.id">
      {{ i + 1 }}
    </v-tab>
  </v-tabs>

  <v-tabs-window v-model="tabsModel">
    <v-tabs-window-item v-for="u in uploads" :key="u.id" :value="u.id" eager class="text-center">
      <view-image-step-upload
        v-if="u.__typename == 'ImageStepUpload'"
        :src="asImageExtension(u).url"
        :max-width="props.maxImageWidth"
        :max-height="props.maxImageHeight"
        :options="u"
        class="mx-auto mt-3"
      />
      <view-video-step-upload
        v-if="u.__typename == 'VideoStepUpload'"
        :src="asVideoExtension(u).url"
        width="100%"
        height="100%"
        :options="u"
      />
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<style scoped lang="scss"></style>
