<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  UpdateImageStepUpload,
  UpdateImageStepUploadDataQuery,
  UpdateImageStepUploadDataQueryVariables,
  UpdateImageStepUploadMutation,
  UpdateImageStepUploadMutationVariables,
} from '@/generated/graphql'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { mockUpdateImageStepUpload } from '@/generated/graphql-mocks'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import FormImageStepUpload from '@/components/stepUpload/FormImageStepUpload.vue'

const props = defineProps<{
  id: string
}>()
const emit = defineEmits<{
  updated: []
}>()

const { t } = useI18n()

type Model = Partial<UpdateImageStepUpload>
const model = reactive<Model>({})
const modelKeys = Object.keys(mockUpdateImageStepUpload())

type LocalStepUpload = UpdateImageStepUploadDataQuery['product']['imageStepUpload']
const fetchQuery = useQuery<
  UpdateImageStepUploadDataQuery,
  UpdateImageStepUploadDataQueryVariables
>(
  gql`
    query UpdateImageStepUploadData($id: ID!) {
      product {
        imageStepUpload(id: $id) {
          id
          caption
          upload {
            id
          }
        }
      }
    }
  `,
  () => ({ id: props.id }),
)
const uploadId = computed(() => fetchQuery.result.value?.product?.imageStepUpload?.upload?.id)

const mutation = useMutation<UpdateImageStepUploadMutation, UpdateImageStepUploadMutationVariables>(
  gql`
    mutation UpdateImageStepUpload($command: UpdateImageStepUpload!) {
      product {
        updateImageStepUpload(command: $command) {
          id
        }
      }
    }
  `,
)

const dialog = ref<ComponentExposed<typeof UpsertDialog>>()
const form = ref<ComponentExposed<typeof FormImageStepUpload>>()
const open = async () => {
  const data = await fetchQuery.refetch()
  const v = data?.data?.product.imageStepUpload as LocalStepUpload
  modelKeys.forEach((k) => {
    ;(model as Record<string, unknown>)[k as keyof Model] = v[k as keyof LocalStepUpload]
  })
  await dialog.value?.open(() =>
    mutation.mutate({ command: model as UpdateImageStepUpload }).then(() => {
      emit('updated')
    }),
  )
}

defineExpose({ open })
</script>

<template>
  <upsert-dialog ref="dialog" type="update" :validation="form?.validation">
    <form-image-step-upload v-if="!!uploadId" ref="form" v-model="model" :upload-id="uploadId" />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
