import gql from 'graphql-tag'

export const stepProposalFragments = gql`
  fragment StepProposalResultDetails on StepProposalResult {
    errors
    proposals {
      id
      name
      instructionText
      durationInSeconds
      videoUploadProposals {
        id
        fragmentStartInMillis
        fragmentEndInMillis
        upload {
          id
          extension {
            ... on VideoExtension {
              url
            }
          }
        }
      }
    }
  }
`
