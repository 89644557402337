<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import {
  GraphEntityType,
  Mutation,
  SetTreeGraph,
  SetTreeGraphNode,
  StepTreeDataQuery,
  StepTreeDataQueryVariables,
  VersionStatus,
} from '@/generated/graphql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import StepTreeNode from '@/components/step/StepTreeNode.vue'
import { associatedIssueTableIssueLinkFragment } from '@/components/issue/issueFragments'

const props = defineProps<{
  bopId: string
  moduleId: string
  selectedStepId?: string
}>()
const emit = defineEmits<{
  createNode: []
  copyNode: []
  proposeFromMedia: []
  selectStepId: [value: string]
}>()

const { t } = useI18n()

type LocalModule = StepTreeDataQuery['product']['module']
type LocalNode = LocalModule['nodes'][0]
export type StepTreeStepNode = {
  numbering: string
  isSelected: boolean
  ancestryNodeIds: string[]
  stepNode: LocalNode
  children: StepTreeStepNode[]
}

const fetchQuery = useQuery<StepTreeDataQuery, StepTreeDataQueryVariables>(
  gql`
    query StepTreeData($bopId: ID!, $moduleId: ID!) {
      product {
        bop(id: $bopId) {
          versionStatus
        }
        module(id: $moduleId) {
          id
          abbreviation
          nodes {
            id
            parentId
            step {
              id
              name
              stepType {
                id
                name
              }
              durationInSeconds
              issueLinks {
                ...AssociatedIssueTableIssueLink
              }
            }
          }
        }
      }
    }

    ${associatedIssueTableIssueLinkFragment}
  `,
  () => ({
    bopId: props.bopId,
    moduleId: props.moduleId,
  }),
)

const isReleased = computed(
  () => fetchQuery.result.value?.product.bop.versionStatus == VersionStatus.Released,
)
const abbreviation = computed(() => fetchQuery.result.value?.product.module.abbreviation || '')
const stepNodes = computed<LocalNode[]>(() => fetchQuery.result.value?.product.module.nodes || [])
const stepTreeNodes = computed<StepTreeStepNode[]>(() =>
  stepNodes.value
    .filter((n) => !n.parentId)
    .map((n, i) => createStepTreeNode(i, [abbreviation.value], n, [])),
)
function createStepTreeNode(
  index: number,
  parentNumbering: string[],
  node: LocalNode,
  previousNodeIds: string[],
): StepTreeStepNode {
  const ancestryNodeIds = [...previousNodeIds, node.id]
  const ancestryNumbering = [...parentNumbering, `${index + 1}`]
  const children = stepNodes.value
    .filter((n) => n.parentId == node.id)
    .map((n, i) => createStepTreeNode(i, ancestryNumbering, n, ancestryNodeIds))

  return {
    numbering: ancestryNumbering.join('.'),
    stepNode: node,
    children,
    ancestryNodeIds,
    isSelected: node.step.id == props.selectedStepId,
  }
}

export type HighestDurationPerStep = {
  [key: string]: number
}
const highestDurationPerStep = computed<HighestDurationPerStep>(() => {
  const map = {}
  highestDurationForLevel(stepTreeNodes.value, map)
  return map
})
function highestDurationForLevel(nodes: StepTreeStepNode[], map: HighestDurationPerStep) {
  const viable = nodes.filter((n) => n.children.length == 0)
  const highest = Math.max(...viable.map((n) => n.stepNode.step.durationInSeconds))

  nodes.forEach((n) => {
    map[n.stepNode.step.id] = highest
    highestDurationForLevel(n.children, map)
  })
}

const notifySaved = ref(false)
function stepNodesToGraphNodes(nodes: LocalNode[]): SetTreeGraphNode[] {
  return nodes.map((n) => ({
    id: n.id,
    parentId: n.parentId,
    entityType: GraphEntityType.Step,
    entityId: n.step.id,
  }))
}

const setGraphMutation = useMutation<Mutation, { graph: SetTreeGraph }>(gql`
  mutation setGraphForModule($graph: SetTreeGraph!) {
    product {
      setModuleGraph(command: $graph)
    }
  }
`)

function setNodes(nodes: LocalNode[]) {
  const treeNodes: SetTreeGraphNode[] = stepNodesToGraphNodes(nodes)
  setGraphMutation
    .mutate({
      graph: {
        entityId: props.moduleId,
        nodes: treeNodes,
      },
    })
    .then(() => {
      notifySaved.value = true
      fetchQuery.refetch()
    })
}

function getNode(nodeId: string) {
  return stepNodes.value.find((n) => n.id == nodeId) as LocalNode
}
function moveNode(movedNodeId: string, targetNodeId: string, after: boolean) {
  const currentIndex = stepNodes.value.findIndex((n) => n.id == movedNodeId)
  let targetIndex = stepNodes.value.findIndex((n) => n.id == targetNodeId)
  if (after) {
    targetIndex++
  }
  if (!after && currentIndex < targetIndex) {
    targetIndex--
  }

  getNode(movedNodeId).parentId = getNode(targetNodeId).parentId

  const newNodes = [...stepNodes.value]
  newNodes.splice(targetIndex, 0, newNodes.splice(currentIndex, 1)[0])
  setNodes(newNodes)
}
function moveBefore(movedNodeId: string, targetNodeId: string) {
  moveNode(movedNodeId, targetNodeId, false)
}
function moveAfter(movedNodeId: string, targetNodeId: string) {
  moveNode(movedNodeId, targetNodeId, true)
}
function makeFirstChild(movedNodeId: string, targetNodeId: string) {
  getNode(movedNodeId).parentId = targetNodeId
  setNodes(stepNodes.value)
}

defineExpose({ refresh: fetchQuery.refetch })
</script>

<template>
  <h2 class="mb-2" style="position: relative">
    {{ t('component.stepTree.title') }}
    <v-progress-circular
      v-if="fetchQuery.loading.value || setGraphMutation.loading.value"
      indeterminate
      size="20"
    />
    <v-btn-group v-if="!isReleased" class="float-right" density="compact">
      <v-btn
        icon="add_circle"
        :title="t('component.stepTree.buttonCreate')"
        @click="$emit('createNode')"
      />
      <v-btn
        icon="content_copy"
        :title="t('component.stepTree.buttonCopy')"
        @click="$emit('copyNode')"
      />
      <v-btn
        icon="auto_awesome"
        :title="t('component.stepTree.buttonProposeFromFile')"
        @click="$emit('proposeFromMedia')"
      />
    </v-btn-group>
  </h2>

  <div
    v-if="stepTreeNodes.length == 0 && !fetchQuery.loading.value && !isReleased"
    class="emptyState"
  >
    <v-btn color="primary" @click="$emit('proposeFromMedia')">
      {{ t('component.stepTree.buttonProposeFromFile') }}
      <v-icon class="ml-1" icon="auto_awesome" />
    </v-btn>
    <v-btn @click="$emit('copyNode')">
      {{ t('component.stepTree.buttonCopy') }} <v-icon class="ml-1" icon="content_copy" />
    </v-btn>
    <v-btn @click="$emit('createNode')">
      {{ t('component.stepTree.buttonCreate') }} <v-icon class="ml-1" icon="add_circle" />
    </v-btn>
  </div>

  <step-tree-node
    v-for="n in stepTreeNodes"
    :key="n.stepNode.id"
    :drag-drop-disabled="isReleased"
    :node="n"
    :selected-step-id="props.selectedStepId"
    :highest-duration-per-step="highestDurationPerStep"
    :module-id="props.moduleId"
    @move-before="moveBefore"
    @move-after="moveAfter"
    @make-first-child="makeFirstChild"
    @select-step-id="(v) => $emit('selectStepId', v)"
  />
</template>

<style scoped lang="scss">
.emptyState {
  position: relative;
  text-align: center;
  margin-top: 2em;

  button {
    display: block !important;
    width: 25em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
