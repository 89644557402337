<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  CreateVideoStepUpload,
  CreateVideoStepUploadMutation,
  CreateVideoStepUploadMutationVariables,
} from '@/generated/graphql'
import { computed, reactive, ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { PickedUpload } from '@/components/upload/UploadPicker.vue'
import useSave from '@/composables/useSave'
import FormVideoStepUpload from '@/components/stepUpload/FormVideoStepUpload.vue'
import { ComponentExposed } from 'vue-component-type-helpers'

const props = defineProps<{
  stepId: string
  upload: PickedUpload
}>()
const emit = defineEmits<{
  created: []
}>()

const { t } = useI18n()

type Model = Partial<CreateVideoStepUpload>
const model = reactive<Model>({})
const mutation = useMutation<CreateVideoStepUploadMutation, CreateVideoStepUploadMutationVariables>(
  gql`
    mutation CreateVideoStepUpload($command: CreateVideoStepUpload!) {
      product {
        createVideoStepUpload(command: $command) {
          id
        }
      }
    }
  `,
)
const mutate = () => {
  model.stepId = props.stepId
  model.uploadId = props.upload.id
  return mutation.mutate({ command: model as CreateVideoStepUpload }).then(() => emit('created'))
}

const form = ref<ComponentExposed<typeof FormVideoStepUpload>>()
const save = computed(() => useSave(mutate, form.value?.validation))
</script>

<template>
  <form-video-step-upload ref="form" v-model="model" :upload-id="props.upload.id" />
  <v-btn color="primary" class="float-right" v-bind="save.saveButtonProps.value">
    {{ t('button.add') }}
  </v-btn>
</template>

<style scoped lang="scss"></style>
