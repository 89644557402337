<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  ArticleSearchQuery,
  ArticleViewDataQuery,
  ArticleViewDataQueryVariables,
  ArticleViewDeleteArticleMutation,
  ArticleViewDeleteArticleMutationVariables,
  ArticleViewUpdateArticleMutation,
  ArticleViewUpdateArticleMutationVariables,
  UpdateArticle,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import TextField from '@/components/input/TextField.vue'
import { required } from '@/validation'
import DataTable from '@/components/dataTable/DataTable.vue'
import ItemEditIcon from '@/components/button/ButtonEdit.vue'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import ArticleUnitTag from '@/components/article/ArticleUnitTag.vue'
import UnitSelect from '@/components/input/UnitSelect.vue'
import IconArticleType from '@/components/icons/IconArticleType.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import { mockUpdateArticle } from '@/generated/graphql-mocks'
import CreateArticleDialog from '@/components/article/CreateArticleDialog.vue'

type LocalArticle = ArticleViewDataQuery['article']['articles'][0]
const { t } = useI18n()

const searchQuery = reactive<ArticleSearchQuery>({})
const fetchQuery = useQuery<ArticleViewDataQuery, ArticleViewDataQueryVariables>(
  gql`
    query ArticleViewData($query: ArticleSearchQuery!) {
      article {
        articles(query: $query) {
          id
          type
          articleNumber
          revision
          unit
          name
          released
        }
      }
    }
  `,
  () => ({
    query: searchQuery,
  }),
  () => ({
    debounce: 500,
  }),
)
const items = computed(() => fetchQuery.result.value?.article.articles || [])
const tableItems = computed(() => items.value.slice(0, 100))

const createDialog = ref<ComponentExposed<typeof CreateArticleDialog>>()

type UpdateModel = Partial<UpdateArticle>
const updateModelKeys = Object.keys(mockUpdateArticle())
const updateModel = reactive<UpdateModel>({})
const updateValidation = useVuelidate<UpdateModel>({ unit: { required } }, updateModel)
const updateMutation = useMutation<
  ArticleViewUpdateArticleMutation,
  ArticleViewUpdateArticleMutationVariables
>(gql`
  mutation ArticleViewUpdateArticle($command: UpdateArticle!) {
    article {
      updateArticle(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (article: LocalArticle) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = article[k as keyof LocalArticle]
  })
  await updateDialog.value?.open(() =>
    updateMutation
      .mutate({ command: updateModel as UpdateArticle })
      .then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<
  ArticleViewDeleteArticleMutation,
  ArticleViewDeleteArticleMutationVariables
>(gql`
  mutation ArticleViewDeleteArticle($id: ID!) {
    article {
      deleteArticle(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalArticle) => {
  const name = item.name || `${item.articleNumber} ${item.revision}`
  await deleteDialog.value?.open(name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const hasMoreData = computed(() => items.value.length > tableItems.value.length)
const headers = [
  { key: 'type', title: t('entity.article.field.type'), width: 50 },
  { key: 'articleNumber', title: t('entity.article.field.articleNumber'), width: 300 },
  { key: 'revision', title: t('entity.article.field.revision'), width: 100 },
  { key: 'unit', title: t('entity.article.field.unit'), width: 100 },
  { key: 'name', title: t('entity.article.field.name') },
  { key: 'actions', width: 100, sortable: false, align: 'end' },
]
const sort = [
  { key: 'articleNumber', order: 'asc' },
  { key: 'revision', order: 'asc' },
]

function cannotEditReason(item: LocalArticle): string | undefined {
  if (item.released) {
    return t('entity.article.disableEditBecauseReleased')
  }

  return undefined
}
function cannotDeleteReason(item: LocalArticle): string | undefined {
  if (item.released) {
    return t('entity.article.disableDeleteBecauseReleased')
  }

  return undefined
}
</script>
<template>
  <h1>{{ t('entity.article.plural') }}</h1>

  <data-table
    :items="tableItems"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    :has-more-data="hasMoreData"
    density="comfortable"
  >
    <template #item.type="{ item }">
      <icon-article-type :type="item.type" />
    </template>
    <template #item.unit="{ item }">
      <article-unit-tag :model-value="item.unit" />
    </template>
    <template #header.actions>
      <data-table-search v-model="searchQuery.containsString" :loading="fetchQuery.loading" />
      <data-table-create-button @click="createDialog?.open()" />
    </template>
    <template #item.actions="{ item }: { item: LocalArticle }">
      <item-edit-icon :disable-reason="cannotEditReason(item)" @click="doUpdate(item)" />
      <item-delete-icon :disable-reason="cannotDeleteReason(item)" @click="doDelete(item)" />
    </template>
  </data-table>

  <async-delete-dialog ref="deleteDialog" hindrance />
  <create-article-dialog ref="createDialog" @created="fetchQuery.refetch()" />
  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <unit-select
      v-model="updateModel.unit"
      :label="t('entity.article.field.unit')"
      required
      :validation="updateValidation.unit"
    />
    <text-field
      v-model="updateModel.name"
      :label="t('entity.article.field.name')"
      :validation="updateValidation.name"
    />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
