<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onUnmounted, reactive, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  ImageOptions,
  UploadPickerDataQuery,
  UploadPickerDataQueryVariables,
  UploadPickerSelectedUploadsQuery,
  UploadPickerSelectedUploadsQueryVariables,
  UploadQuery,
  UploadSortDirection,
  UploadSortField,
} from '@/generated/graphql'
import TextField from '@/components/input/TextField.vue'
import UploadButton from '@/components/upload/UploadButton.vue'
import useUploads from '@/composables/useUploads'
import { DateTime } from 'luxon'
import UploadPreview from '@/components/upload/UploadPreview.vue'

export type PickedUpload = {
  id: string
  contentType: string
  name: string
}
type UploadTableItem = PickedUpload & {
  isSelectable: boolean
}

const model = defineModel<boolean>({ required: true })
const props = defineProps<{
  mimeTypes: string[]
  pickOne?: boolean
}>()
const emit = defineEmits<{
  pickOne: [value: PickedUpload]
  pickMultiple: [value: PickedUpload[]]
}>()

const { t } = useI18n()

const searchQuery = reactive<UploadQuery>({
  results: 12,
  page: 0,
  sortBy: UploadSortField.CreatedAt,
  sortDirection: UploadSortDirection.Desc,
})
watch(
  () => props.mimeTypes,
  (v) => {
    searchQuery.inContentTypes = v.length == 0 ? undefined : v
  },
  { immediate: true },
)

const selectedUploadIds = ref<string[]>([])
watch(model, (v) => {
  if (v) {
    selectedUploadIds.value = []
  }
})
const onUploadClick = (item: UploadTableItem) => {
  if (!item.isSelectable) {
    return
  }

  if (props.pickOne) {
    selectedUploadIds.value = [item.id]
  } else {
    if (selectedUploadIds.value.includes(item.id)) {
      selectedUploadIds.value = selectedUploadIds.value.filter((u) => u != item.id)
    } else {
      selectedUploadIds.value.push(item.id)
    }
  }
}

type UploadPickerUpload = UploadPickerDataQuery['upload']['search'][0]
const imageOptions: ImageOptions = {
  height: 200,
  width: 200,
}
const fetchQuery = useQuery<UploadPickerDataQuery, UploadPickerDataQueryVariables>(
  gql`
    query UploadPickerData($query: UploadQuery!, $imageOptions: ImageOptions!) {
      upload {
        search(query: $query) {
          id
          contentType
          name
          createdAt
          extension {
            ... on ImageExtension {
              url(options: $imageOptions)
            }
          }
        }
      }
    }
  `,
  () => ({
    query: searchQuery,
    imageOptions,
  }),
  {
    debounce: 500,
  },
)
const fetchedUploads = computed(() => fetchQuery.result.value?.upload.search || [])

const selectedUploadsQuery = useQuery<
  UploadPickerSelectedUploadsQuery,
  UploadPickerSelectedUploadsQueryVariables
>(
  gql`
    query UploadPickerSelectedUploads($ids: [ID!]!) {
      upload {
        uploads(ids: $ids) {
          id
          contentType
          name
        }
      }
    }
  `,
  () => ({
    ids: selectedUploadIds.value,
  }),
)
function selectUploads() {
  const selectedUploads = selectedUploadsQuery.result.value?.upload.uploads || []

  if (props.pickOne) {
    emit('pickOne', selectedUploads[0])
  } else {
    emit('pickMultiple', selectedUploads)
  }

  model.value = false
}

const { uploadsInProgress, addUploadDoneListener } = useUploads()
onUnmounted(
  addUploadDoneListener((upload) => {
    searchQuery.page = 0
    fetchQuery.refetch()

    if (props.pickOne) {
      selectedUploadIds.value = [upload.id]
      return
    }

    selectedUploadIds.value.push(upload.id)
  }),
)
const justUploadedIds = ref<string[]>([])
const items = computed(() => {
  const inProgress: UploadPickerUpload[] = uploadsInProgress.value
    .filter((u) => justUploadedIds.value.includes(u.id))
    .map((u) => ({
      id: u.id,
      createdAt: DateTime.now().toString(),
      name: u.fileName,
      contentType: u.file.type,
      isSelectable: false,
      extension: {},
    }))
  const uploads = fetchedUploads.value.map((u) => ({ ...u, isSelectable: true }))
  return [...inProgress, ...uploads]
})

const canSelectUploads = computed(
  () => selectedUploadIds.value.length != 0 && !selectedUploadsQuery.loading.value,
)
</script>

<template>
  <v-dialog v-model="model" max-width="1400" scrollable>
    <v-card>
      <v-card-title>{{ t('component.uploadPicker.title') }}</v-card-title>
      <v-card-subtitle opacity="1">
        <v-row>
          <v-col cols="6">
            <text-field
              v-model="searchQuery.nameContains"
              :label="t('component.uploadPicker.search')"
            />
          </v-col>
          <v-col offset="1" cols="4" class="mt-3">
            <upload-button
              :mime-types="props.mimeTypes"
              @new-upload-ids="(v) => justUploadedIds.push(...v)"
            />
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-row dense>
          <v-col v-if="searchQuery.page > 0" cols="12" @click="searchQuery.page--">
            <v-btn style="width: 100%" variant="flat" :loading="fetchQuery.loading.value">
              <v-icon>navigate_before</v-icon> {{ t('button.previous') }}
            </v-btn>
          </v-col>
          <v-col v-for="u in items" :key="u.id" cols="3">
            <v-card
              style="height: 100%"
              :variant="selectedUploadIds.includes(u.id) ? 'tonal' : 'elevated'"
              density="compact"
              @click="onUploadClick(u)"
            >
              <v-card-title style="height: 220px">
                <upload-preview
                  :upload="u"
                  :height="imageOptions.height"
                  :width="imageOptions.width"
                  icon-size="100"
                />
              </v-card-title>
              <v-card-text>
                {{ u.name }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="fetchedUploads.length == searchQuery.results"
            cols="12"
            class="text-center"
            @click="searchQuery.page++"
          >
            <v-btn style="width: 100%" variant="text" :loading="fetchQuery.loading.value">
              {{ t('button.next') }} <v-icon>navigate_next</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="model = false">
          {{ t('component.uploadPicker.closeButton') }}
        </v-btn>
        <v-btn
          variant="elevated"
          color="primary"
          :disabled="!canSelectUploads"
          @click="selectUploads()"
        >
          {{ t('component.uploadPicker.selectButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
