<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  AllocateArticleToStepCommand,
  ManualArticleAllocationRowAllocationMutation,
  ManualArticleAllocationRowAllocationMutationVariables,
} from '@/generated/graphql'
import { reactive, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { minValue, required } from '@/validation'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { authzIsOrgPartAllocation } from '@/app'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import ArticleQuantityField from '@/components/input/ArticleQuantityField.vue'
import ArticlePicker from '@/components/input/ArticlePicker.vue'
import IconManual from '@/components/icons/IconManual.vue'
import { mockAllocateArticleToStepCommand } from '@/generated/graphql-mocks'

const props = defineProps<{
  stepId: string
}>()
const emit = defineEmits<{
  allocated: []
}>()

const { t } = useI18n()

type Model = Partial<AllocateArticleToStepCommand>
const modelKeys = Object.keys(mockAllocateArticleToStepCommand())
const model = reactive<Model>({})

const validation = useVuelidate<Model>(
  {
    articleId: { required },
    milliQuantity: { required, min: minValue(0) },
  },
  model,
)
const resetModel = () => {
  modelKeys.forEach((k) => {
    ;(model as Record<string, unknown>)[k] = undefined
  })
  model.stepId = props.stepId
  validation.value.$reset()
}
watch(() => props.stepId, resetModel, { immediate: true })

const notifySaved = ref(false)
const setManualAllocationMutation = useMutation<
  ManualArticleAllocationRowAllocationMutation,
  ManualArticleAllocationRowAllocationMutationVariables
>(gql`
  mutation ManualArticleAllocationRowAllocation($command: AllocateArticleToStepCommand!) {
    article {
      allocateArticleToStep(command: $command)
    }
  }
`)

function setManualAllocation() {
  validation.value.$touch()
  if (validation.value.$invalid) {
    return
  }

  setManualAllocationMutation
    .mutate({ command: model as AllocateArticleToStepCommand })
    .then(() => {
      resetModel()
      notifySaved.value = true
      emit('allocated')
    })
}
</script>

<template>
  <tr v-if="authzIsOrgPartAllocation">
    <td>
      <icon-manual />
    </td>
    <td colspan="2">
      <article-picker
        v-model="model.articleId"
        :validation="validation.articleId"
        density="compact"
      />
    </td>
    <td colspan="2">
      <article-quantity-field
        v-model="model.milliQuantity"
        :validation="validation.milliQuantity"
        density="compact"
        class="ma-0"
      >
        <template #append>
          <v-btn
            density="compact"
            variant="flat"
            color="primary"
            :disabled="validation.$invalid"
            :loading="setManualAllocationMutation.loading.value"
            icon="add"
            @click="setManualAllocation()"
          />
        </template>
      </article-quantity-field>
    </td>
  </tr>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss">
.form-field {
  margin-bottom: 0.3em;
}
</style>
