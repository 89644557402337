<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import StepProposalConverter, {
  StepProposalConverterStepProposal,
} from '@/components/step/StepProposalConverter.vue'

const props = defineProps<{
  proposals: StepProposalConverterStepProposal[]
  productId: string
  moduleId: string
}>()
const emit = defineEmits<{
  created: []
  done: []
}>()

const { t } = useI18n()

const proposals = ref<StepProposalConverterStepProposal[]>([])
watch(
  () => props.proposals,
  (v) => (proposals.value = v),
  { immediate: true },
)

function removeProposal(proposalId: string) {
  proposals.value = proposals.value.filter((p) => p.id != proposalId)
}
const onCreated = (proposalId: string) => {
  removeProposal(proposalId)
  emit('created')
}
watch(proposals, (v) => {
  if (v.length == 0) {
    emit('done')
  }
})

defineOptions({ inheritAttrs: false })
</script>

<template>
  <v-alert
    v-if="proposals.length == 0"
    type="warning"
    class="mx-auto"
    :title="t('component.stepProposalsConverter.titleNoSteps')"
    width="400"
    variant="tonal"
  >
    {{ t('component.stepProposalsConverter.textNoSteps') }}
  </v-alert>

  <v-alert
    v-if="proposals.length > 0"
    type="info"
    :title="t('component.stepProposalsConverter.titleSteps')"
    variant="tonal"
  >
    {{ t('component.stepProposalsConverter.textSteps') }}
  </v-alert>
  <step-proposal-converter
    v-for="p in proposals"
    :key="p.id"
    :proposal="p"
    :product-id="props.productId"
    :module-id="props.moduleId"
    @created="onCreated(p.id)"
    @discard="removeProposal(p.id)"
  />
</template>

<style scoped lang="scss"></style>
