import { enLocale } from '@/translations/en'
import { PermissionOrganization } from '@/generated/graphql'

export type AllLocales = {
  [index: string]: Locale
}
export type Locale = {
  button: {
    save: string
    ok: string
    cancel: string
    close: string
    add: string
    create: string
    edit: string
    delete: string
    view: string
    copy: string
    next: string
    previous: string
    discard: string
  }
  language: {
    [key: string]: string
  }
  notification: {
    failed: string
    saved: string
    duplicated: string
    deleted: string
    deleteTitle: string
    deleteWarningTitle: string
    deleteHindranceText: string
  }
  navigationDrawer: {
    platform: string
    organization: string
    currentUploads: string
  }
  section: {
    processEditor: string
    operations: string
    checks: string
    issues: string
  }
  view: {
    home: {
      welcome: string
      selectOrganization: string
      buttonGo: string
    }
    organization: {
      bops: {
        buttonCreateEmpty: string
        buttonDuplicate: string
        cannotCreateMessage: string
        releaseTooltip: string
      }
      bopProcess: {
        downloadMbomButton: string
        menuView: string
        menuAddChild: string
        menuAddParent: string
        menuUpdateNode: string
        menuDeleteParent: string
        menuDeleteNode: string
        menuMoveAbove: string
      }
      home: {
        productDescription: string
        operatorTitle: string
        operatorDescription: string
        productionBatchDescription: string
        issueDescription: string
      }
      module: {
        downloadMbomButton: string
      }
      operatorProduct: {
        title: string
        titleBatchesInProgress: string
        noData: string
      }
      operatorProductConfiguration: {
        noData: string
        buttonCreateProductionBatch: string
        buttonViewModule: string
      }
    }
  }
  component: {
    accountMenu: {
      switchOrganization: string
      logOut: string
    }
    agreeToTerms: {
      title: string
      text: string
      labelReadCheck: string
      buttonAgree: string
    }
    apiErrorSnackbar: {
      error: {
        unknown: {
          title: string
          message: string
        }
        apiUnreachable: {
          title: string
          message: string
        }
        privileges: {
          title: string
          message: string
        }
      }
      actionMoreDetails: string
      actionDismiss: string
      detailsTitle: string
      detailsClose: string
      detailsCopy: string
    }
    bopNavigationDrawer: {
      headerTimePerModule: string
      headerAssociatedEboms: string
      headerAssociatedIssues: string
    }
    bopSelector: {
      buttonModules: string
      buttonBOPs: string
    }
    articleAllocationList: {
      source: string
      sourceManual: string
      sourceEbom: string
    }
    bopReleaseConfirmation: {
      title: string
      message: string
      confirmation: string
      cancelButton: string
      releaseButton: string
    }
    bulkAllocationSuggestionActions: {
      createTitle: string
      createButton: string
      createConfirmation: string
      acceptTitle: string
      acceptButton: string
      acceptConfirmation: string
      rejectTitle: string
      rejectButton: string
      rejectConfirmation: string
    }
    completeProductionBatch: {
      title: string
      text: string
      incompleteChecks: string
      buttonActivator: string
      buttonCancel: string
      buttonSave: string
    }
    confirmDialog: {
      title: string
      ok: string
      cancel: string
    }
    createIssueDialog: {
      title: string
      linkToSerialNumbers: string
    }
    createStep: {
      title: string
    }
    createStepUploads: {
      title: string
    }
    csvEbomLoader: {
      headerEbom: string
      headerColumns: string
      instructionColumns: string
      ebomName: string
      encoding: string
      rootArticleNumber: string
      columnArticleNumber: string
      columnArticleName: string
      columnArticleRevision: string
      columnUnit: string
      columnQuantity: string
      columnParentArticleNumber: string
      buttonProcess: string
    }
    dataTable: {
      loading: string
      noData: string
      page: string
      itemsPerPage: string
      moreData: string
    }
    deleteDialog: {
      title: string
      warningTitle: string
      hindranceText: string
      deleteText: string
      // Old
      areYouSure: string
      delete: string
      cancel: string
    }
    downloadMbomDialog: {
      title: string
      closeButton: string
      downloadBopMbom: string
    }
    downloadModuleMbomButton: {
      labelLong: string
      labelShort: string
    }
    duplicateModule: {
      title: string
    }
    duplicateStep: {
      title: string
    }
    ebomBulkAllocateDialog: {
      title: string
      text: string
    }
    ebomDiffDialog: {
      title: string
      selectLabel: string
      diffButton: string
      closeButton: string
    }
    ebomDiffSameParts: {
      title: string
      swapExplanation: string
      swapButton: string
      notificationSwapped: string
    }
    ebomDiffTree: {
      noDifferences: string
    }
    ebomLoader: {
      buttonActivate: string
      buttonCancel: string
      titlePickEbom: string
      titleLoadEbom: string
      titlePromoteEbomParts: string
      titleEbomPartAllocationSuggestions: string
      titleDone: string
      loadEbomFromCsv: string
      doneHeader: string
      doneMessage: string
      doneButton: string
    }
    ebomPartAllocationSuggestionsLoader: {
      header: string
      message: string
      skipButton: string
      createSuggestionsButton: string
      successMessage: string
    }
    ebomPartDetails: {
      buttonPromoteEbomPart: string
      quantityAllocatedToSteps: string
      headerStepAllocations: string
      tableHeaderModule: string
      tableHeaderStep: string
      tableHeaderQuantity: string
      promoteDialogTitle: string
      promoteDialogContent: string
      labelSuggestion: string
    }
    ebomPartTree: {
      title: string
      filterOpenSuggestionsHint: string
      filterAllocationDisparityHint: string
    }
    editDialog: {
      save: string
      cancel: string
    }
    helpDialog: {
      title: string
      text: string
    }
    iconArchived: {
      archived: string
      notArchived: string
    }
    iconConsumable: {
      title: string
    }
    iconEbom: {
      title: string
    }
    iconIssue: {
      title: string
    }
    iconManual: {
      title: string
    }
    iconPart: {
      title: string
    }
    iconProductionBatch: {
      title: string
    }
    issueCommentList: {
      buttonUnresolve: string
      buttonResolve: string
    }
    mainAppBar: {
      buttonOrgHome: string
      buttonOperator: string
    }
    operatorCurrentProductionBatch: {
      title: string
      deselectButton: string
    }
    operatorModuleStepper: {
      finalizeTitle: string
      doneTitle: string
      previousButton: string
      nextButton: string
      issueButton: string
    }
    operatorProductionBatchDrawer: {
      titleBatchesInProgress: string
    }
    operatorStepTree: {
      title: string
    }
    pageHeader: {
      createButton: string
    }
    promoteEbomParts: {
      title: string
      instructions: string
      buttonSelectNonAssemblies: string
      buttonPromote: string
      buttonDone: string
      successMessage: string
    }
    siteButtonPicker: {
      noData: string
    }
    stepPickerDialog: {
      title: string
      select: string
    }
    stepProposalsConverter: {
      titleNoSteps: string
      textNoSteps: string
      titleSteps: string
      textSteps: string
    }
    stepProposalsFromFileDialog: {
      titlePickFile: string
      titleLoad: string
      titleConvertToSteps: string
      explanationTitle: string
      explanationText: string
      explanationMedia: string
      explanationPdf: string
      disclaimerTitle: string
      disclaimerText: string
      inputUpload: string
      buttonCreateProposals: string
      errorTitle: string
      errorText: string
    }
    stepProposalsProgress: {
      optimizing: {
        title: string
        message: string
      }
      extracting: {
        title: string
        message: string
      }
      clustering: {
        title: string
        message: string
      }
      done: {
        title: string
        message: string
      }
    }
    stepProposalsFromMediaLoader: {
      inputLanguage: string
    }
    stepProposalsFromPdfLoader: {
      inputFirstPage: string
      inputLastPage: string
    }
    stepTree: {
      title: string
      buttonProposeFromFile: string
      buttonCreate: string
      buttonCopy: string
    }
    stepToolbar: {
      buttonDelete: string
    }
    textAreaField: {
      markdownDetails: string
    }
    uploadButton: {
      instructions: string
    }
    uploadPicker: {
      title: string
      search: string
      maybeMoreItems: string
      selectButton: string
      closeButton: string
      newUploadButton: string
      tableItemsPerPage: string
      tableNoData: string
    }
    upsertDialog: {
      title: {
        create: string
        update: string
      }
    }
    userPermissions: {
      title: string
      organizationPermissions: string
      addOrganization: string
      field: {
        userPermissions: string
      }
    }
    userProductionBatches: {
      buttonOtherUsers: string
      buttonTransferBatch: string
      confirmTransferText: string
    }
    versionStatus: {
      status: {
        RELEASED: string
        DRAFT: string
      }
    }
  }
  validation: {
    abbreviation: {
      regex: string
      unique: string
    }
    filename: {
      regex: string
    }
    isChecked: {
      notChecked: string
    }
    maxDifference: {
      message: string
    }
    milliQuantity: {
      min: string
      max: string
    }
    normalName: {
      regex: string
    }
    normalDescription: {
      regex: string
    }
    orgSlug: {
      pattern: string
      taken: string
    }
    unique: {
      unique: string
    }
    userEmail: {
      taken: string
    }
  }
  permission: {
    [k in PermissionKey]: TranslatedPermission
  }
  entity: {
    article: TranslatedEntity & {
      field: {
        articleNumber: string
        revision: string
        name: string
        unit: string
        quantity: string
        type: string
        source: string
      }
      type: {
        PART: string
        CONSUMABLE: string
      }
      unit: {
        full: {
          GRAM: string
          KILOGRAM: string
          LITER: string
          MILLILITER: string
          METER: string
          MILLIMETER: string
          PIECES: string
        }
        abbreviated: {
          GRAM: string
          KILOGRAM: string
          LITER: string
          MILLILITER: string
          METER: string
          MILLIMETER: string
          PIECES: string
        }
      }
      validation: {
        articleAndRevisionTaken: string
      }
      disableEditBecauseReleased: string
      disableDeleteBecauseReleased: string
    }
    bop: TranslatedEntity & {
      field: {
        version: string
        versionStatus: string
      }
    }
    check: TranslatedEntity & {
      field: {
        poNumber: string
        serialNumber: string
        reportingUser: string
        released: string
        value: string
        confirmed: string
        milliQuantity: string
        text: string
        updatedAt: string
        createdAt: string
      }
    }
    checkConfiguration: TranslatedEntity & {
      field: {
        type: string
        description: string
        upperLimit: string
        lowerLimit: string
        isRequired: string
      }
      type: {
        CONFIRMATION: string
        NUMERIC: string
        STRING: string
      }
    }
    ebom: TranslatedEntity & {
      field: {
        name: string
      }
    }
    ebomPart: TranslatedEntity & {
      isPromoted: string
      field: {
        articleNumber: string
        revision: string
        name: string
        quantity: string
        articlePath: string
      }
    }
    ebomPartAllocation: TranslatedEntity
    graphNode: TranslatedEntity & {
      field: {
        milliQuantity: string
      }
    }
    issue: TranslatedEntity & {
      field: {
        name: string
        description: string
        assignedToUsers: string
        uploads: string
        createdAt: string
        createdByUser: string
        updatedAt: string
        links: string
        resolvedAt: string
        resolved: string
      }
      filter: {
        assignedToUser: string
        isResolved: string
        poNumbers: string
        serialNumbers: string
        bopId: string
      }
    }
    issueComment: TranslatedEntity & {
      field: {
        description: string
        uploads: string
        uploadIds: string
        createdAt: string
        createdByUser: string
        resolvedAt: string
        resolved: string
        updatedAt: string
      }
    }
    issueLink: TranslatedEntity & {
      field: {
        bopId: string
        moduleId: string
        stepId: string
        serialNumber: string
        serialNumbers: string
        poNumber: string
        articleId: string
      }
      type: {
        bop: string
        module: string
        step: string
        serialNumber: string
        article: string
      }
    }
    manualPartAllocation: TranslatedEntity
    module: TranslatedEntity & {
      field: {
        name: string
        abbreviation: string
        description: string
      }
    }
    organization: TranslatedEntity & {
      field: {
        name: string
        slug: string
      }
    }
    product: TranslatedEntity & {
      field: {
        name: string
        abbreviation: string
        description: string
        image: string
      }
    }
    productConfiguration: TranslatedEntity & {
      field: {
        name: string
        abbreviation: string
        description: string
        image: string
      }
    }
    productionBatch: TranslatedEntity & {
      field: {
        poNumber: string
        serialNumbers: string
        completed: string
        assignedUser: string
      }
      filter: {
        poNumber: string
        serialNumbers: string
        completed: string
      }
    }
    site: TranslatedEntity & {
      field: {
        name: string
        abbreviation: string
        archived: string
      }
    }
    stepType: TranslatedEntity & {
      field: {
        name: string
        archived: string
        released: string
      }
    }
    step: TranslatedEntity & {
      field: {
        name: string
        stepType: string
        durationInSeconds: string
        instructionText: string
        images: string
        tools: string
      }
    }
    stepInput: TranslatedEntity & {
      field: {
        milliQuantity: string
      }
    }
    stepOutput: TranslatedEntity & {
      field: {
        milliQuantity: string
      }
    }
    stepUpload: TranslatedEntity & {
      field: {
        caption: string
        fragmentStart: string
        fragmentEnd: string
        fragmentLoop: string
      }
    }
    tool: TranslatedEntity & {
      field: {
        name: string
        description: string
        site: string
        images: string
        archived: string
        released: string
      }
    }
    upload: TranslatedEntity & {
      field: {
        name: string
        contentType: string
        createdAt: string
      }
    }
    user: TranslatedEntity & {
      field: {
        name: string
        firstname: string
        lastname: string
        email: string
        needsPassword: string
      }
    }
  }
}
export type TranslatedEntity = {
  singular: string
  plural: string
  deleteWarning?: string
  disableDeleteBecause?: string
  editWarning?: string
  disableEditBecause?: string
  conceptExplanation?: string
}
type PermissionKey = keyof typeof PermissionOrganization
export type TranslatedPermission = {
  name: string
  description: string
}

export const locales: AllLocales = {
  en: enLocale,
}
