import { createApp, h, provide, watch } from 'vue'
import App from './App.vue'
import router from './router'
import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import { apolloClient } from '@/apollo'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { customLightTheme } from '@/theme'
import { aliases, md } from 'vuetify/iconsets/md'
import '@/assets/main.css'
import * as Sentry from '@sentry/vue'
import { httpClientIntegration } from '@sentry/integrations'
import axios from 'axios'
import { currentOrganization, currentUserAndPermissions } from '@/app'
import authInit, { authentication } from '@/authentication'
import { i18n } from '@/i18n'
import initLocalTracing from '@/tracing'

const environment = import.meta.env.VITE_ENVIRONMENT as string

// Vuetify
const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  theme: {
    defaultTheme: 'customLightTheme',
    themes: {
      customLightTheme,
    },
  },
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    },
  },
})

// App init
const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

// Tracing
if (environment == 'development') {
  initLocalTracing()
}

// Sentry
if (environment != 'development') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.browserProfilingIntegration(),
      httpClientIntegration({
        failedRequestStatusCodes: [[400, 599]],
      }),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
      }),
      Sentry.replayCanvasIntegration({}),
      Sentry.captureConsoleIntegration({ levels: ['error', 'warn'] }),
    ],
    environment,
    release: import.meta.env.VITE_GIT_SHA as string,
    autoSessionTracking: true,
    enableTracing: true,
    tracePropagationTargets: [/^http:\/\/localhost/, /^https:\/\/api\.baseboard\.pro/],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    logErrors: true,
    attachProps: true,
    attachStacktrace: true,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    sendDefaultPii: true,
  })
}

// Axios
axios.defaults.baseURL = import.meta.env.VITE_REST_API_BASE_URL as string
axios.defaults.maxBodyLength = 25 * 1024 * 1024
watch(
  currentUserAndPermissions,
  (v) => {
    if (!v) {
      return
    }
    if (environment == 'development') {
      axios.defaults.headers.common['baseboard-user-id'] = v.user.id
    }
  },
  { deep: true },
)
watch(
  currentOrganization,
  (v) => {
    if (!v) {
      return
    }
    axios.defaults.headers.common['baseboard-organization-id'] = v.id
  },
  { deep: true },
)
watch(authentication, (v) => {
  if (!v) {
    return
  }
  axios.defaults.headers.common['Authorization'] = `Bearer ${v.token}`
})

// App run
app.use(router)
app.use(vuetify)
app.use(i18n)

authInit()
  .then(() => app.mount('#app'))
  .catch((reason) => console.error(`Cannot initialize Keycloak: ${reason}`))
