<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import UploadPicker from '@/components/upload/UploadPicker.vue'
import { ref } from 'vue'

const { t } = useI18n()
const model = ref(true)
const picked = ref()
</script>

<template>
  <pre>{{ picked }}</pre>
  <upload-picker v-model="model" :mime-types="[]" @pick-multiple="(v) => (picked = v)" />
</template>

<style scoped lang="scss"></style>
