<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  Article,
  ArticleViewArticleAndRevisionTakenQuery,
  ArticleViewArticleAndRevisionTakenQueryVariables,
  ArticleViewCreateArticleMutation,
  ArticleViewCreateArticleMutationVariables,
  CreateArticle,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import TextField from '@/components/input/TextField.vue'
import { required } from '@/validation'
import UnitSelect from '@/components/input/UnitSelect.vue'
import { helpers } from '@vuelidate/validators'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import { mockCreateArticle } from '@/generated/graphql-mocks'
import ArticleTypePicker from '@/components/input/ArticleTypePicker.vue'

type CreatedArticle = Pick<Article, 'id' | 'articleNumber' | 'revision' | 'name'>
const emit = defineEmits<{
  created: [value: CreatedArticle]
}>()

const { t } = useI18n()

type CreateModel = Partial<CreateArticle>
const createModelKeys = Object.keys(mockCreateArticle())
const createModel = reactive<CreateModel>({})
const articleAndRevisionTakenFetchQuery = useQuery<
  ArticleViewArticleAndRevisionTakenQuery,
  ArticleViewArticleAndRevisionTakenQueryVariables
>(
  gql`
    query CreateArticleDialogTaken($articleNumber: String!, $revision: String!) {
      article {
        articles(query: { articleNumber: $articleNumber, revision: $revision }) {
          id
        }
      }
    }
  `,
  () => ({
    articleNumber: createModel.articleNumber as string,
    revision: createModel.revision as string,
  }),
  () => ({
    debounce: 500,
    enabled: !!createModel.articleNumber && !!createModel.revision,
  }),
)
const articleAndRevisionTakenById = computed<string | undefined>(
  () => articleAndRevisionTakenFetchQuery.result.value?.article.articles[0]?.id,
)
const validateArticleAndRevisionTaken = helpers.withMessage(
  () => t('entity.article.validation.articleAndRevisionTaken'),
  () => !articleAndRevisionTakenById.value,
)
const createValidation = useVuelidate<CreateModel>(
  {
    type: { required },
    articleNumber: { required, validateArticleAndRevisionTaken },
    revision: { required, validateArticleAndRevisionTaken },
    unit: { required },
  },
  createModel,
  { $stopPropagation: true },
)

const createMutation = useMutation<
  ArticleViewCreateArticleMutation,
  ArticleViewCreateArticleMutationVariables
>(gql`
  mutation ArticleViewCreateArticle($command: CreateArticle!) {
    article {
      createArticle(command: $command) {
        id
        articleNumber
        revision
        name
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const open = async () => {
  createModelKeys.forEach((k) => {
    ;(createModel as Record<string, unknown>)[k] = undefined
  })
  await createDialog.value?.open(() =>
    createMutation
      .mutate({ command: createModel as CreateArticle })
      .then((res) => emit('created', res?.data?.article.createArticle as CreatedArticle)),
  )
}

defineExpose({ open })
</script>

<template>
  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <article-type-picker
      v-model="createModel.type"
      required
      :label="t('entity.article.field.type')"
    />
    <text-field
      v-model="createModel.articleNumber"
      :label="t('entity.article.field.articleNumber')"
      required
      :validation="createValidation.articleNumber"
    />
    <text-field
      v-model="createModel.revision"
      :label="t('entity.article.field.revision')"
      required
      :validation="createValidation.revision"
    />
    <unit-select
      v-model="createModel.unit"
      :label="t('entity.article.field.unit')"
      required
      :validation="createValidation.unit"
    />
    <text-field
      v-model="createModel.name"
      :label="t('entity.article.field.name')"
      :validation="createValidation.name"
    />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
