<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ArticleType } from '@/generated/graphql'
import SelectField from '@/components/input/SelectField.vue'

const model = defineModel<ArticleType>({ required: false })

const { t } = useI18n()

const items = Object.values(ArticleType).map((l) => ({
  title: t(`entity.article.type.${l}`),
  value: l,
}))
</script>

<template>
  <select-field v-model="model" :items="items" />
</template>

<style scoped lang="scss"></style>
