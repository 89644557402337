import { Ref, ref, unref, watch } from 'vue'
import { Validation } from '@vuelidate/core'
import { scrollToFirstInvalidField } from '@/validation'

type NotFunction = string | number | boolean | bigint | object
type ValidationProvider = Validation | Ref<Validation>
export default function useAsyncDialog<T extends NotFunction>(
  validation?: ValidationProvider,
): {
  openAsyncDialog: () => Promise<T | undefined>
  asyncDialogResolve: Ref<((val?: T) => void) | undefined>
  asyncDialogLoading: Ref<boolean>
  asyncDialogIsOpen: Ref<boolean>
} {
  type OptionalT = T | undefined
  type Resolver = (val?: T) => void
  type TProvider = undefined | T | (() => OptionalT) | (() => Promise<OptionalT>)

  const loading = ref(false)
  const isOpen = ref(false)
  const openPromiseResolver = ref<Resolver>()

  const reset = () => {
    loading.value = false
    openPromiseResolver.value = undefined
    isOpen.value = false
  }
  const open = async (): Promise<OptionalT> => {
    isOpen.value = true
    return new Promise<OptionalT>((resolve) => {
      openPromiseResolver.value = (val: TProvider) => {
        if (validation) {
          const realValidation = unref(validation)
          realValidation.$touch()
          if (realValidation.$invalid) {
            scrollToFirstInvalidField()
            loading.value = false
            return
          }
        }

        if (typeof val !== 'function') {
          resolve(val)
          reset()
          return
        }

        const ret = val()
        if (ret instanceof Promise) {
          loading.value = true
          ret.then((result) => {
            resolve(result)
            reset()
          })
          return
        }

        resolve(ret)
        reset()
        return
      }
    })
  }
  watch(isOpen, (v) => {
    if (!openPromiseResolver.value || v) {
      return
    }

    openPromiseResolver.value() // Return void if closed without interaction
  })

  return {
    openAsyncDialog: open,
    asyncDialogResolve: openPromiseResolver,
    asyncDialogLoading: loading,
    asyncDialogIsOpen: isOpen,
  }
}
