<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { authzIsOrgMember, authzIsOrgOperator, authzIsOrgProductManagement } from '@/app'
import { useRouter } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const { t } = useI18n()
const router = useRouter()

const fetchQuery = useQuery(gql`
  query OrgHomeData {
    account {
      me {
        organization {
          id
          name
        }
      }
    }
  }
`)
const orgName = computed(() => fetchQuery.result.value?.account.me.organization.name)
</script>

<template>
  <h1>{{ orgName }}</h1>
  <v-row>
    <v-col v-if="authzIsOrgProductManagement" cols="4" lg="3">
      <v-card height="100%" @click="router.push({ name: 'products' })">
        <v-card-title class="text-center">
          <v-icon size="90" :title="t('entity.product.plural')">precision_manufacturing</v-icon>
        </v-card-title>
        <v-card-text>
          <h2>{{ t('entity.product.plural') }}</h2>
          {{ t('view.organization.home.productDescription') }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="authzIsOrgOperator" cols="4" lg="3">
      <v-card height="100%" @click="router.push({ name: 'operatorHome' })">
        <v-card-title class="text-center">
          <v-icon size="90" :title="t('view.organization.home.operatorTitle')"> warehouse </v-icon>
        </v-card-title>
        <v-card-text>
          <h2>{{ t('view.organization.home.operatorTitle') }}</h2>
          {{ t('view.organization.home.operatorDescription') }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="authzIsOrgMember" cols="4" lg="3">
      <v-card height="100%" @click="router.push({ name: 'production-batches' })">
        <v-card-title class="text-center">
          <v-icon size="90" :title="t('entity.productionBatch.plural')"> library_add_check </v-icon>
        </v-card-title>
        <v-card-text>
          <h2>{{ t('entity.productionBatch.plural') }}</h2>
          {{ t('view.organization.home.productionBatchDescription') }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="authzIsOrgMember" cols="4" lg="3">
      <v-card height="100%" @click="router.push({ name: 'issues' })">
        <v-card-title class="text-center">
          <v-icon size="90" :title="t('entity.issue.plural')"> flag </v-icon>
        </v-card-title>
        <v-card-text>
          <h2>{{ t('entity.issue.plural') }}</h2>

          {{ t('view.organization.home.issueDescription') }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>
