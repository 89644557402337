<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  CreateVideoStepUpload,
  FormVideoStepUploadDataQuery,
  FormVideoStepUploadDataQueryVariables,
  UpdateVideoStepUpload,
} from '@/generated/graphql'
import { computed, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import TextField from '@/components/input/TextField.vue'
import { caption, fragmentInMillis } from '@/components/stepUpload/stepUploadValidation'
import Checkbox from '@/components/input/Checkbox.vue'
import ViewVideoStepUpload from '@/components/stepUpload/ViewVideoStepUpload.vue'
import MilliQuantityField from '@/components/input/MilliQuantityField.vue'

type Model = CreateVideoStepUpload & UpdateVideoStepUpload
const model = defineModel<Model>({ required: true })
const props = defineProps<{
  uploadId: string
}>()

const { t } = useI18n()

const validation = useVuelidate<Model>(
  {
    caption,
    fragmentStartInMillis: fragmentInMillis,
    fragmentEndInMillis: fragmentInMillis,
  },
  model,
)

const onRangeCheck = () => {
  const start = model.value.fragmentStartInMillis
  const end = model.value.fragmentEndInMillis
  if (start && end && end < start) {
    model.value.fragmentStartInMillis = end
    model.value.fragmentEndInMillis = start
  }
}

const max = ref<number>()
const onDuration = (millis: number) => {
  max.value = millis
}

const rangeModel = computed(() => {
  const start = model.value.fragmentStartInMillis
  const end = model.value.fragmentEndInMillis

  return [start || 0, end || max.value || 0]
})
const onRangeModel = (range: number[]) => {
  model.value.fragmentStartInMillis = range[0]
  model.value.fragmentEndInMillis = range[1]
}

type LocalUpload = FormVideoStepUploadDataQuery['upload']['upload']
type LocalExtension = LocalUpload['extension'] & { typename: 'VideoExtension' }
const fetchQuery = useQuery<FormVideoStepUploadDataQuery, FormVideoStepUploadDataQueryVariables>(
  gql`
    query FormVideoStepUploadData($id: ID!) {
      upload {
        upload(id: $id) {
          extension {
            ... on VideoExtension {
              url
            }
          }
        }
      }
    }
  `,
  () => ({ id: props.uploadId }),
)
const extension = computed<LocalUpload | undefined>(
  () => fetchQuery.result.value?.upload?.upload?.extension as LocalExtension | undefined,
)
const videoUrl = computed(() => extension.value?.url)

defineExpose({ validation })
</script>

<template>
  <div class="text-center mt-3">
    <view-video-step-upload
      v-if="!!videoUrl"
      :src="videoUrl"
      :options="model"
      width="100%"
      height="100%"
      @duration-in-millis="onDuration"
    />
  </div>
  <text-field
    v-model="model.caption"
    :validation="validation.caption"
    :label="t('entity.stepUpload.field.caption')"
  />
  <v-range-slider
    :max="max"
    :min="0"
    :step="100"
    :model-value="rangeModel"
    hide-details
    @update:model-value="onRangeModel"
  />
  <v-row>
    <v-col cols="4">
      <milli-quantity-field
        v-model="model.fragmentStartInMillis"
        :validation="validation.fragmenStartInMillis"
        :label="t('entity.stepUpload.field.fragmentStart')"
        @blur="onRangeCheck"
      />
    </v-col>
    <v-col cols="4">
      <milli-quantity-field
        v-model="model.fragmentEndInMillis"
        :validation="validation.fragmenEndInMillis"
        :label="t('entity.stepUpload.field.fragmentEnd')"
        @blur="onRangeCheck"
      />
    </v-col>
    <v-col>
      <checkbox
        v-model="model.fragmentLoop"
        class="mt-7"
        :label="t('entity.stepUpload.field.fragmentLoop')"
      />
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>
