<script setup lang="ts" generic="T, MM">
import { ToolPickerDataQuery, ToolPickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { SelectOption } from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { itemSortByName } from '@/app'
import AutocompleteField from '@/components/input/AutocompleteField.vue'

const props = defineProps<{
  siteId: string
}>()

const fetchQuery = useQuery<ToolPickerDataQuery, ToolPickerDataQueryVariables>(
  gql`
    query ToolPickerData($siteId: ID!) {
      site {
        site(id: $siteId) {
          tools {
            id
            name
            description
            archived
            imageUploads {
              imageUrl(options: { width: 100, height: 100 })
            }
          }
        }
      }
    }
  `,
  () => ({
    siteId: props.siteId,
  }),
)
const options = computed<SelectOption<string>[]>(() =>
  itemSortByName(
    fetchQuery.result.value?.site.site.tools.filter((t) => !t.archived),
    (c) => c.name,
  ).map((c) => ({
    title: c.name,
    value: c.id,
  })),
)
</script>

<template>
  <autocomplete-field :items="options" multiple />
</template>

<style scoped lang="scss"></style>
