<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  CreateImageStepUpload,
  FormImageStepUploadDataQuery,
  FormImageStepUploadDataQueryVariables,
  ImageExtension,
  ImageOptions,
  UpdateImageStepUpload,
} from '@/generated/graphql'
import useVuelidate from '@vuelidate/core'
import TextField from '@/components/input/TextField.vue'
import { caption } from '@/components/stepUpload/stepUploadValidation'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'
import ViewImageStepUpload from '@/components/stepUpload/ViewImageStepUpload.vue'

type Model = CreateImageStepUpload & UpdateImageStepUpload
const model = defineModel<Model>({ required: true })
const props = defineProps<{
  uploadId: string
}>()

const { t } = useI18n()

const imageOptions: ImageOptions = {
  width: 500,
  height: 500,
}
const fetchQuery = useQuery<FormImageStepUploadDataQuery, FormImageStepUploadDataQueryVariables>(
  gql`
    query FormImageStepUploadData($id: ID!, $options: ImageOptions!) {
      upload {
        upload(id: $id) {
          extension {
            ... on ImageExtension {
              url(options: $options)
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.uploadId,
    options: imageOptions,
  }),
)
const extension = computed(
  () => fetchQuery.result.value?.upload.upload.extension as ImageExtension | undefined,
)
const imageUrl = computed(() => extension.value?.url)

const validation = useVuelidate<Model>({ caption }, model)

defineExpose({ validation })
</script>

<template>
  <div class="text-center">
    <view-image-step-upload
      v-if="!!imageUrl"
      :src="imageUrl"
      :max-width="imageOptions.width"
      :max-height="imageOptions.height"
      :options="model"
      class="mx-auto mt-3"
    />
  </div>
  <text-field
    v-model="model.caption"
    :validation="validation.caption"
    :label="t('entity.stepUpload.field.caption')"
  />
</template>

<style scoped lang="scss"></style>
