<script setup lang="ts" generic="T, MM">
import { StepOutputPickerDataQuery, StepOutputPickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed, watch } from 'vue'
import { itemSortByName } from '@/app'
import { useI18n } from 'vue-i18n'

const model = defineModel<string | undefined>()
const props = defineProps<{
  stepId?: string
  label?: string
}>()
const emit = defineEmits<{
  milliQuantity: [value: number]
}>()

const { t } = useI18n()

const label = computed(() => props.label || t('entity.stepOutput.singular'))

watch(
  () => props.stepId,
  () => {
    model.value = undefined
  },
)
const disabled = computed(() => !props.stepId)

const fetchQuery = useQuery<StepOutputPickerDataQuery, StepOutputPickerDataQueryVariables>(
  gql`
    query StepOutputPickerData($stepId: ID!) {
      product {
        step(id: $stepId) {
          outputs {
            id
            milliQuantity
            article {
              id
              articleNumber
              name
              revision
              unit
            }
          }
        }
      }
    }
  `,
  () => ({
    stepId: props.stepId as string,
  }),
  () => ({
    enabled: !disabled.value,
  }),
)
const items = computed(() => fetchQuery.result.value?.product.step.outputs || [])
const options = computed<SelectOption<string>[]>(() =>
  disabled.value
    ? []
    : itemSortByName(items.value, (n) => n.article.articleNumber).map((n) => {
        const quantity = n.milliQuantity / 1000
        const unit = t(`entity.article.unit.abbreviated.${n.article.unit}`)
        return {
          title: `${n.article.articleNumber}/${n.article.revision} ${n.article.name || ''} - ${quantity} ${unit}`,
          value: n.id,
        }
      }),
)

watch([model, items], () => {
  const milliQuantity = items.value.find((i) => i.id == model.value)?.milliQuantity
  if (milliQuantity) {
    emit('milliQuantity', milliQuantity)
  }
})
</script>

<template>
  <select-field
    v-model="model"
    :label="label"
    :loading="fetchQuery.loading.value"
    :items="options"
    :disabled="disabled"
  />
</template>

<style scoped lang="scss"></style>
