<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  CreateStepInput,
  CreateStepInputMutation,
  CreateStepInputMutationVariables,
  DeleteStepInputMutation,
  DeleteStepInputMutationVariables,
  StepInputsSectionDataQuery,
  StepInputsSectionDataQueryVariables,
  UpdateStepInput,
  UpdateStepInputMutation,
  UpdateStepInputMutationVariables,
} from '@/generated/graphql'
import { computed, reactive, ref } from 'vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import useVuelidate from '@vuelidate/core'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import MilliQuantityField from '@/components/input/MilliQuantityField.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import { mockCreateStepInput, mockUpdateStepInput } from '@/generated/graphql-mocks'
import ArticleName from '@/components/article/ArticleName.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'
import ModulePicker from '@/components/input/ModulePicker.vue'
import StepPicker from '@/components/input/StepPicker.vue'
import StepOutputPicker from '@/components/input/StepOutputPicker.vue'
import { maxMilliQuantity, required } from '@/validation'

const props = defineProps<{
  bopId: string
  moduleId: string
  stepId: string
}>()

type LocalStepInput = StepInputsSectionDataQuery['product']['step']['inputs'][0]

const { t } = useI18n()

const fetchQuery = useQuery<StepInputsSectionDataQuery, StepInputsSectionDataQueryVariables>(
  gql`
    query StepInputsSectionData($stepId: ID!) {
      product {
        step(id: $stepId) {
          inputs {
            id
            milliQuantity
            output {
              id
              milliQuantity
              step {
                id
                name
              }
              module {
                id
                name
              }
              article {
                id
                articleNumber
                revision
                name
                unit
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    stepId: props.stepId,
  }),
)
const inputs = computed(() => fetchQuery.result.value?.product.step.inputs || [])

const createModuleId = ref<string>()
const createStepId = ref<string>()
const createMaxMilliQuantity = ref<number>(Infinity)

type CreateModel = Partial<CreateStepInput>
const createModelKeys = Object.keys(mockCreateStepInput())
const createModel = reactive<CreateModel>({})
const createValidationRules = computed(() => ({
  milliQuantity: { required, max: maxMilliQuantity(createMaxMilliQuantity) },
  stepOutputId: { required },
}))
const createValidation = useVuelidate<CreateModel>(createValidationRules, createModel)
const createMutation = useMutation<CreateStepInputMutation, CreateStepInputMutationVariables>(gql`
  mutation CreateStepInput($command: CreateStepInput!) {
    product {
      createStepInput(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  createModelKeys.forEach((k) => {
    ;(createModel as Record<string, unknown>)[k] = undefined
  })
  createModel.moduleId = props.moduleId
  createModel.stepId = props.stepId

  await createDialog.value?.open(() =>
    createMutation
      .mutate({ command: createModel as CreateStepInput })
      .then(() => fetchQuery.refetch()),
  )
}

type UpdateModel = Partial<UpdateStepInput>
const updateModelKeys = Object.keys(mockUpdateStepInput())
const updateModel = reactive<UpdateModel>({})
const updateMaxMilliQuantity = ref<number>(Infinity)
const updateValidationRules = computed(() => ({
  milliQuantity: { required, max: maxMilliQuantity(updateMaxMilliQuantity) },
}))
const updateValidation = useVuelidate<UpdateModel>(updateValidationRules, updateModel)
const updateMutation = useMutation<UpdateStepInputMutation, UpdateStepInputMutationVariables>(gql`
  mutation UpdateStepInput($command: UpdateStepInput!) {
    product {
      updateStepInput(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (_: unknown, row: { item: LocalStepInput }) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = row.item[k as keyof LocalStepInput]
  })
  updateMaxMilliQuantity.value = row.item.output.milliQuantity

  await updateDialog.value?.open(() =>
    updateMutation
      .mutate({ command: updateModel as UpdateStepInput })
      .then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<DeleteStepInputMutation, DeleteStepInputMutationVariables>(gql`
  mutation DeleteStepInput($id: ID!) {
    product {
      deleteStepInput(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalStepInput) => {
  await deleteDialog.value?.open(t('entity.stepInput.singular'), () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const headers = [
  {
    key: 'module',
    title: t('entity.module.singular'),
  },
  {
    key: 'step',
    title: t('entity.step.singular'),
  },
  {
    key: 'article',
    title: t('entity.article.singular'),
  },
  {
    key: 'milliQuantity',
    title: t('entity.stepInput.field.milliQuantity'),
    width: 50,
  },
  {
    key: 'actions',
    width: 100,
    align: 'end',
  },
]
const sort = [{ key: 'module', order: 'asc' }]
</script>

<template>
  <data-table
    :items="inputs"
    :headers="headers"
    :loading="fetchQuery.loading"
    :sort-by="sort"
    density="comfortable"
    hide-default-footer
    disable-sort
    @click:row="doUpdate"
  >
    <template #header.actions>
      <data-table-create-button @click="doCreate" />
    </template>
    <template #item.actions="{ item }: { item: LocalStepInput }">
      <item-delete-icon @click="doDelete(item)" />
    </template>
    <template #item.module="{ item }: { item: LocalStepInput }">
      {{ item.output.module.name }}
    </template>
    <template #item.step="{ item }: { item: LocalStepInput }">
      {{ item.output.step.name }}
    </template>
    <template #item.article="{ item }: { item: LocalStepInput }">
      <article-name :article="item.output.article" />
    </template>
    <template #item.milliQuantity="{ item }: { item: LocalStepInput }">
      <article-quantity :milli-quantity="item.milliQuantity" :unit="item.output.article.unit" />
    </template>
  </data-table>

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <module-picker v-model="createModuleId" :bop-id="props.bopId" />
    <step-picker v-model="createStepId" :module-id="createModuleId" />
    <step-output-picker
      v-model="createModel.stepOutputId"
      :step-id="createStepId"
      :validation="createValidation.stepOutputId"
      @milli-quantity="(v) => (createMaxMilliQuantity = v)"
    />
    <milli-quantity-field
      v-model="createModel.milliQuantity"
      :validation="createValidation.milliQuantity"
      required
      :label="t('entity.stepInput.field.milliQuantity')"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <milli-quantity-field
      v-model="updateModel.milliQuantity"
      required
      :validation="updateValidation.milliQuantity"
      :label="t('entity.stepInput.field.milliQuantity')"
    />
  </upsert-dialog>

  <async-delete-dialog ref="deleteDialog" />
</template>

<style scoped lang="scss"></style>
