<script setup lang="ts">
import { VersionStatus } from '@/generated/graphql'
import SelectField from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { authzIsOrgProductManagement } from '@/app'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  label: string
  productConfigId: string
  siteId: string
  modelValue: string | undefined
  bops: BopSelectorBop[]
  isLast: boolean
}>()
defineEmits<{
  'update:modelValue': [value: string]
}>()

export type BopSelectorBop = {
  id: string
  version: number
  versionStatus: VersionStatus
}

const options = computed(() =>
  props.bops.map((b) => ({
    value: b.id,
    title: `${b.version} (${b.versionStatus})`,
  })),
)

const { t } = useI18n()
const router = useRouter()
</script>

<template>
  <select-field
    class="mb-2"
    :label="props.label"
    :items="options"
    :model-value="props.modelValue"
    @update:model-value="(v: string) => $emit('update:modelValue', v)"
  />

  <div class="text-right">
    <v-btn-group density="compact" variant="elevated">
      <v-btn
        v-if="authzIsOrgProductManagement"
        @click="
          router.push({
            name: 'bops',
            params: { productConfigId: props.productConfigId, siteId: props.siteId },
          })
        "
      >
        {{ t('component.bopSelector.buttonBOPs') }}
      </v-btn>
      <v-btn
        :disabled="!props.modelValue"
        color="primary"
        @click="
          router.push({
            name: 'bopProcess',
            params: {
              productConfigId: props.productConfigId,
              siteId: props.siteId,
              bopId: props.modelValue,
            },
          })
        "
      >
        {{ t('component.bopSelector.buttonModules') }}
      </v-btn>
    </v-btn-group>

    <v-divider v-if="props.isLast" class="mt-5 mb-3" />
  </div>
</template>

<style scoped lang="scss"></style>
