<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  ImageOptions,
  Move,
  StepUploadListDataQuery,
  StepUploadListDataQueryVariables,
} from '@/generated/graphql'
import { computed, ref } from 'vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import CreateStepUploads from '@/components/stepUpload/CreateStepUploads.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import ButtonDelete from '@/components/button/ButtonDelete.vue'
import UpdateStepUpload from '@/components/stepUpload/UpdateStepUpload.vue'
import DragDropHandle from '@/components/utils/DragDropHandle.vue'
import UploadPreview from '@/components/upload/UploadPreview.vue'

const props = defineProps<{
  stepId: string
}>()

type LocalStepUpload = StepUploadListDataQuery['product']['step']['uploads'][0]

const { t } = useI18n()

const imageOptions: ImageOptions = {
  width: 200,
  height: 200,
}
const fetchQuery = useQuery<StepUploadListDataQuery, StepUploadListDataQueryVariables>(
  gql`
    query StepUploadListData($stepId: ID!, $imageOptions: ImageOptions!) {
      product {
        step(id: $stepId) {
          uploads {
            ... on IStepUpload {
              id
              upload {
                name
                contentType
                extension {
                  __typename
                }
              }
            }
            ... on ImageStepUpload {
              upload {
                extension {
                  ... on ImageExtension {
                    url(options: $imageOptions)
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    stepId: props.stepId,
    imageOptions,
  }),
)
const uploads = computed(() => fetchQuery.result.value?.product.step.uploads || [])

const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const deleteMutation = useMutation(gql`
  mutation StepUploadListDelete($id: ID!) {
    product {
      deleteStepUpload(id: $id)
    }
  }
`)
const doDelete = async (id: string) => {
  await deleteDialog.value?.open(t('entity.stepUpload.singular'), () =>
    deleteMutation.mutate({ id }).then(() => fetchQuery.refetch()),
  )
}

const createDialog = ref<ComponentExposed<typeof CreateStepUploads>>()
const updateDialog = ref<ComponentExposed<typeof UpdateStepUpload>>()
const onUpdate = (u: LocalStepUpload) => {
  updateDialog.value?.open(u.id, u.__typename)
}

const moveMutation = useMutation(gql`
  mutation StepUploadListMove($stepId: ID!, $command: Move!) {
    product {
      moveStepUpload(inStepId: $stepId, command: $command)
    }
  }
`)
const onMove = (command: Move) => {
  moveMutation.mutate({ stepId: props.stepId, command }).then(() => fetchQuery.refetch())
}
</script>

<template>
  <v-card density="compact">
    <v-card-text class="pa-0">
      <v-list class="pa-0">
        <v-list-item class="font-weight-bold">
          <template #append>
            <data-table-create-button @click="createDialog?.open()" />
          </template>
        </v-list-item>
        <v-divider />
        <v-list-item v-if="uploads.length == 0" class="text-center font-italic">
          {{ t('component.dataTable.noData') }}
        </v-list-item>
        <v-list-item v-for="(u, index) in uploads" :key="u.id" @click="onUpdate(u)">
          <template #prepend>
            <v-avatar rounded="0">
              <upload-preview :upload="u.upload" />
            </v-avatar>
          </template>

          <v-list-item-title>
            {{ u.upload.name }}
          </v-list-item-title>

          <template #append>
            <button-delete @click="doDelete(u.id)" />
            <drag-drop-handle
              :id="u.id"
              group="stepUploads"
              :is-last="index == uploads.length - 1"
              @moved="onMove"
            />
          </template>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>

  <create-step-uploads ref="createDialog" :step-id="props.stepId" @created="fetchQuery.refetch()" />
  <update-step-upload ref="updateDialog" @updated="fetchQuery.refetch()" />
  <async-delete-dialog ref="deleteDialog" />
</template>

<style scoped lang="scss"></style>
