<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  CreateStepOutput,
  CreateStepOutputMutation,
  CreateStepOutputMutationVariables,
  DeleteStepOutputMutation,
  DeleteStepOutputMutationVariables,
  StepOutputsSectionDataQuery,
  StepOutputsSectionDataQueryVariables,
  UpdateStepOutput,
  UpdateStepOutputMutation,
  UpdateStepOutputMutationVariables,
} from '@/generated/graphql'
import { computed, reactive, ref } from 'vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import useVuelidate from '@vuelidate/core'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import MilliQuantityField from '@/components/input/MilliQuantityField.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import { mockCreateStepOutput, mockUpdateStepOutput } from '@/generated/graphql-mocks'
import ArticleName from '@/components/article/ArticleName.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'
import ArticlePicker from '@/components/input/ArticlePicker.vue'
import { itemSortByName } from '@/app'
import { required } from '@/validation'

const props = defineProps<{
  moduleId: string
  stepId: string
}>()

type LocalStepOutput = StepOutputsSectionDataQuery['product']['step']['outputs'][0]

const { t } = useI18n()

const fetchQuery = useQuery<StepOutputsSectionDataQuery, StepOutputsSectionDataQueryVariables>(
  gql`
    query StepOutputsSectionData($stepId: ID!) {
      product {
        step(id: $stepId) {
          outputs {
            id
            milliQuantity
            article {
              id
              name
              articleNumber
              revision
              unit
            }
          }
        }
      }
    }
  `,
  () => ({
    stepId: props.stepId,
  }),
)
const outputs = computed(() =>
  itemSortByName(fetchQuery.result.value?.product.step.outputs, (i) => i?.article?.articleNumber),
)

type CreateModel = Partial<CreateStepOutput>
const createModelKeys = Object.keys(mockCreateStepOutput())
const createModel = reactive<CreateModel>({})
const createValidation = useVuelidate<CreateModel>(
  {
    articleId: { required },
    milliQuantity: { required },
  },
  createModel,
)
const createMutation = useMutation<CreateStepOutputMutation, CreateStepOutputMutationVariables>(gql`
  mutation CreateStepOutput($command: CreateStepOutput!) {
    product {
      createStepOutput(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  createModelKeys.forEach((k) => {
    ;(createModel as Record<string, unknown>)[k] = undefined
  })
  createModel.moduleId = props.moduleId
  createModel.stepId = props.stepId

  await createDialog.value?.open(() =>
    createMutation
      .mutate({ command: createModel as CreateStepOutput })
      .then(() => fetchQuery.refetch()),
  )
}

type UpdateModel = Partial<UpdateStepOutput>
const updateModelKeys = Object.keys(mockUpdateStepOutput())
const updateModel = reactive<UpdateModel>({})
const updateValidation = useVuelidate<UpdateModel>(
  {
    milliQuantity: { required },
  },
  updateModel,
)
const updateMutation = useMutation<UpdateStepOutputMutation, UpdateStepOutputMutationVariables>(gql`
  mutation UpdateStepOutput($command: UpdateStepOutput!) {
    product {
      updateStepOutput(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (_: unknown, row: { item: LocalStepOutput }) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = row.item[k as keyof LocalStepOutput]
  })

  await updateDialog.value?.open(() =>
    updateMutation
      .mutate({ command: updateModel as UpdateStepOutput })
      .then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<DeleteStepOutputMutation, DeleteStepOutputMutationVariables>(gql`
  mutation DeleteStepOutput($id: ID!) {
    product {
      deleteStepOutput(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalStepOutput) => {
  await deleteDialog.value?.open(t('entity.stepOutput.singular'), () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const headers = [
  {
    key: 'article',
    title: t('entity.article.singular'),
  },
  {
    key: 'milliQuantity',
    title: t('entity.stepOutput.field.milliQuantity'),
    width: 50,
  },
  {
    key: 'actions',
    width: 100,
    align: 'end',
  },
]
const sort = [{ key: 'article', order: 'asc' }]
</script>

<template>
  <data-table
    :items="outputs"
    :headers="headers"
    :loading="fetchQuery.loading"
    :sort-by="sort"
    density="comfortable"
    hide-default-footer
    disable-sort
    @click:row="doUpdate"
  >
    <template #header.actions>
      <data-table-create-button @click="doCreate" />
    </template>
    <template #item.actions="{ item }: { item: LocalStepOutput }">
      <item-delete-icon @click="doDelete(item)" />
    </template>
    <template #item.article="{ item }: { item: LocalStepOutput }">
      <article-name :article="item.article" />
    </template>
    <template #item.milliQuantity="{ item }: { item: LocalStepOutput }">
      <article-quantity :milli-quantity="item.milliQuantity" :unit="item.article.unit" />
    </template>
  </data-table>

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <article-picker
      v-model="createModel.articleId"
      required
      :label="t('entity.article.singular')"
      :validation="createValidation.articleId"
      with-create
    />
    <milli-quantity-field
      v-model="createModel.milliQuantity"
      required
      :validation="createValidation.milliQuantity"
      :label="t('entity.stepOutput.field.milliQuantity')"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <milli-quantity-field
      v-model="updateModel.milliQuantity"
      :validation="updateValidation.milliQuantity"
      required
      :label="t('entity.stepOutput.field.milliQuantity')"
    />
  </upsert-dialog>

  <async-delete-dialog ref="deleteDialog" />
</template>

<style scoped lang="scss"></style>
