<script setup lang="ts">
import FormField from '@/components/input/FormField.vue'
import { SelectOption } from '@/components/input/SelectField.vue'
import { PartPickerDataQuery, PartPickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import { debounce } from 'lodash'
import CreateArticleDialog from '@/components/article/CreateArticleDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import { VAutocomplete } from 'vuetify/components'
import { Validation } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'

const externalModel = defineModel<string>({ required: false })
const props = defineProps<{
  label?: string
  validation?: Validation
  withCreate?: boolean
}>()

const { t } = useI18n()

export type Model = PartPickerDataQuery['article']['articles'][0]

const searchString = ref<string>()
const debouncedSearchString = ref<string>()
const debouncer = debounce(() => (debouncedSearchString.value = searchString.value), 300)
watch(searchString, debouncer)

const fetchQuery = useQuery<PartPickerDataQuery, PartPickerDataQueryVariables>(
  gql`
    query PartPickerData($searchString: String) {
      article {
        articles(query: { containsString: $searchString }) {
          id
          articleNumber
          revision
          name
        }
      }
    }
  `,
  () => ({
    searchString: searchString.value,
  }),
  {
    debounce: 300,
  },
)

const internalModel = ref<Model>()
watch(
  internalModel,
  (v) => {
    externalModel.value = v?.id
  },
  { deep: true },
)
watch(externalModel, (v) => {
  if (!v) {
    internalModel.value = undefined
  }
})

const toTitle = (a: Model) => `${a.articleNumber}/${a.revision} ${a.name || ''}`
const items = computed<SelectOption<Model>[]>(
  () =>
    fetchQuery.result.value?.article.articles.map((p) => ({
      title: toTitle(p),
      value: p,
    })) || [],
)

const dialog = ref<ComponentExposed<typeof CreateArticleDialog>>()
const autocomplete = ref<VAutocomplete>()
const onCreated = (article: Model) => {
  searchString.value = toTitle(article)
  internalModel.value = article
}
</script>

<template>
  <form-field :label="props.label" :validation="props.validation">
    <v-autocomplete
      ref="autocomplete"
      v-bind="$attrs"
      v-model="internalModel"
      v-model:search="searchString"
      hide-details
      :items="items"
      auto-select-first
      no-filter
      :loading="fetchQuery.loading.value"
      no-data-text="Type to search"
      @blur="validation?.$touch"
    >
      <template #chip="{ item }"> {{ toTitle(item.value) }} </template>
      <template v-if="props.withCreate" #append>
        <v-btn
          icon="add"
          variant="text"
          density="comfortable"
          :title="t('button.create')"
          @click="dialog?.open()"
        />
      </template>
    </v-autocomplete>

    <create-article-dialog ref="dialog" @created="onCreated" />
  </form-field>
</template>
