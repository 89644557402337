<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  CsvExtension,
  GenericExtension,
  ImageExtension,
  PdfExtension,
  Upload,
  VideoExtension,
} from '@/generated/graphql'
import { computed } from 'vue'

type PreviewableBase = Pick<Upload, 'name'>
type PreviewableCsv = PreviewableBase & {
  extension: Pick<CsvExtension, '__typename'>
}
type PreviewableGeneric = PreviewableBase & {
  extension: Pick<GenericExtension, '__typename'>
}
type PreviewableImage = PreviewableBase & {
  extension: Pick<ImageExtension, 'url' | '__typename'>
}
type PreviewablePdf = PreviewableBase & {
  extension: Pick<PdfExtension, '__typename'>
}
type PreviewableVideo = PreviewableBase & {
  extension: Pick<VideoExtension, '__typename'>
}
type PreviewableUpload =
  | PreviewableCsv
  | PreviewableGeneric
  | PreviewableImage
  | PreviewablePdf
  | PreviewableVideo

const props = defineProps<{
  upload: PreviewableUpload
  iconSize?: string
}>()
const type = computed(() => props.upload.extension.__typename)
const image = computed(() =>
  type.value == 'ImageExtension' ? (props.upload as PreviewableImage) : undefined,
)

const { t } = useI18n()
</script>

<template>
  <div class="text-center align-content-center" style="height: 100%">
    <v-img
      v-if="!!image"
      v-bind="$attrs"
      :src="image.extension.url"
      :title="image.name"
      class="mx-auto"
    />
    <v-icon v-if="type == 'VideoExtension'" :size="props.iconSize">movie</v-icon>
  </div>
</template>

<style scoped lang="scss"></style>
