<script setup lang="ts">
import { ImageStepUpload } from '@/generated/graphql'

type LocalImageStepUpload = Omit<ImageStepUpload, 'id' | 'upload'>

const props = defineProps<{
  options: LocalImageStepUpload
}>()

defineOptions({ inheritAttrs: false })
</script>

<template>
  <img v-bind="$attrs" />
  <div class="imageCaption">{{ props.options.caption }}</div>
</template>

<style scoped lang="scss">
.imageCaption {
  text-align: center;
  font-size: 0.95em;
}
</style>
