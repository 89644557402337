<script setup lang="ts">
import { computed } from 'vue'
import { PickedUpload } from '@/components/upload/UploadPicker.vue'
import useContentTypes, { ContentTypeGroup } from '@/composables/useContentTypes'
import CreateImageStepUpload from '@/components/stepUpload/CreateImageStepUpload.vue'
import CreateVideoStepUpload from '@/components/stepUpload/CreateVideoStepUpload.vue'

const props = defineProps<{
  upload: PickedUpload
}>()

const { getContentTypeGroup } = useContentTypes()
const type = computed(() => getContentTypeGroup(props.upload.contentType))
</script>

<template>
  <create-image-step-upload
    v-if="type == ContentTypeGroup.IMAGE"
    :upload="props.upload"
    v-bind="$attrs"
  />
  <create-video-step-upload
    v-if="type == ContentTypeGroup.VIDEO_FOR_BROWSER"
    :upload="props.upload"
    v-bind="$attrs"
  />
</template>

<style scoped lang="scss"></style>
