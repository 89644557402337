<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { ref } from 'vue'

export type DownloadMbomButtonModule = {
  id: string
  name: string
  abbreviation: string
}

const props = defineProps<{
  bopId: string
  module: DownloadMbomButtonModule
  short?: boolean
}>()

const { t } = useI18n()

const loading = ref(false)

const onDownload = async () => {
  loading.value = true
  await axios.get(`/download/mbom/bop/${props.bopId}/${props.module.id}`).then((response) => {
    const contentDisposition = response.headers['content-disposition']
    const filename = contentDisposition.match(/filename="([^"]+)/)[1]
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
  loading.value = false
}
</script>

<template>
  <v-btn :loading="loading" @click="onDownload">
    <template v-if="props.short">
      {{ t('component.downloadModuleMbomButton.labelShort') }}
    </template>
    <template v-else>
      {{ t('component.downloadModuleMbomButton.labelLong', { name: props.module.name }) }}
    </template>
  </v-btn>
</template>

<style scoped lang="scss"></style>
