<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { HomeViewDataQuery, HomeViewDataQueryVariables } from '@/generated/graphql'
import gql from 'graphql-tag'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()

type LocalOrgAndPerms = HomeViewDataQuery['account']['organizations'][0]
const fetchQuery = useQuery<HomeViewDataQuery, HomeViewDataQueryVariables>(gql`
  query HomeViewData {
    account {
      organizations {
        organization {
          id
          name
          slug
        }
      }
    }
  }
`)
const orgOptions = computed<SelectOption<LocalOrgAndPerms>[]>(
  () =>
    fetchQuery.result?.value?.account.organizations.map((o) => ({
      value: o,
      title: o.organization.name,
    })) || [],
)
const selectedOrg = ref<LocalOrgAndPerms | undefined>()
watch(orgOptions, (v) => {
  if (v.length == 1) {
    selectedOrg.value = v[0].value
  }
})

const onGoToOrg = () => {
  router.push({
    name: 'orgHome',
    params: { organizationSlug: selectedOrg.value?.organization?.slug },
  })
}

const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
const videoNumber = randomInteger(0, 5)
</script>

<template>
  <div class="background-video-container">
    <div class="video-overlay" />
    <video :src="`/home-background/${videoNumber}.webm`" loop autoplay muted />
  </div>

  <v-row align="center" justify="center" style="height: 100%">
    <v-col cols="6" lg="4" xl="3">
      <v-card class="mb-10" elevation="2">
        <v-card-title>
          <img src="/img/logo-black.svg" class="logo" />
        </v-card-title>
        <v-card-text class="px-6">
          <select-field
            v-model="selectedOrg"
            :label="t('view.home.selectOrganization')"
            :items="orgOptions"
            :loading="fetchQuery.loading.value"
          />
        </v-card-text>
        <v-card-actions class="justify-center pb-7">
          <v-btn :disabled="!selectedOrg" color="primary" variant="elevated" @click="onGoToOrg">
            {{ t('view.home.buttonGo') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.background-video-container {
  z-index: -1000;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;

  .video-overlay {
    z-index: -998;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  video {
    filter: blur(10px);
    z-index: -999;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
  }
}
.logo {
  display: block;
  width: 80%;
  margin: 3em auto 3em auto;
}
</style>
