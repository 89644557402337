<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import { ImageOptions, ViewStepDataQuery, ViewStepDataQueryVariables } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed } from 'vue'
import IconArticleType from '@/components/icons/IconArticleType.vue'
import ArticleName from '@/components/article/ArticleName.vue'
import Markdown from '@/components/Markdown.vue'
import StepUploadsViewer from '@/components/stepUpload/StepUploadsViewer.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'

const props = defineProps<{
  stepId: string
}>()

type LocalPartAllocation = ViewStepDataQuery['product']['step']['articleAllocations'][0]

const { t } = useI18n()

const imageOptions: ImageOptions = {
  height: 500,
  width: 500,
}
const fetchQuery = useQuery<ViewStepDataQuery, ViewStepDataQueryVariables>(
  gql`
    query ViewStepData($id: ID!, $imageOptions: ImageOptions!) {
      product {
        step(id: $id) {
          id
          durationInSeconds
          instructionText
          name
          stepType {
            id
            name
          }
          toolIds
          tools {
            id
            name
            description
            imageUploads {
              id
              imageUrl(options: $imageOptions)
            }
          }
          articleAllocations {
            ... on IArticleAllocation {
              id
              milliQuantity
              article {
                id
                type
                name
                unit
                revision
                articleNumber
              }
            }
          }
          inputs {
            id
            milliQuantity
            output {
              id
              milliQuantity
              module {
                id
                name
              }
              step {
                id
                name
              }
              article {
                id
                name
                articleNumber
                revision
                unit
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.stepId,
    imageOptions,
  }),
)
const step = computed(() => fetchQuery.result.value?.product.step)
const articleAllocations = computed(
  () => (step.value?.articleAllocations as LocalPartAllocation[]) || [],
)
const inputs = computed(() => step.value?.inputs || [])
</script>

<template>
  <template v-if="!step">
    <v-skeleton-loader type="article" />
    <v-skeleton-loader type="card" />
    <v-skeleton-loader type="article" />
  </template>

  <template v-if="step">
    <h1>
      {{ step.name }}
      <v-chip>{{ step.stepType.name }}</v-chip>
    </h1>

    <v-row>
      <v-col>
        <template v-if="inputs.length > 0">
          <h2>{{ t('entity.stepInput.plural') }}</h2>
          <v-table density="comfortable">
            <thead>
              <tr>
                <td>{{ t('entity.module.singular') }} & {{ t('entity.step.singular') }}</td>
                <td>{{ t('entity.article.singular') }}</td>
                <td>{{ t('entity.stepInput.field.milliQuantity') }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in inputs" :key="item.id">
                <td>
                  <strong>{{ item.output.module.name }}</strong> <br />{{ item.output.step.name }}
                </td>
                <td><article-name :article="item.output.article" /></td>
                <td>
                  <article-quantity
                    :milli-quantity="item.milliQuantity"
                    :unit="item.output.article.unit"
                    unit-abbreviated
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </template>

        <template v-if="articleAllocations.length > 0">
          <h2>{{ t('entity.article.plural') }}</h2>
          <v-table density="comfortable">
            <thead>
              <tr>
                <td>{{ t('entity.article.field.type') }}</td>
                <td>{{ t('entity.article.singular') }}</td>
                <td>{{ t('entity.article.field.quantity') }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="a in articleAllocations" :key="a.id">
                <td><icon-article-type :type="a.article.type" /></td>
                <td><article-name :article="a.article" /></td>
                <td>
                  <article-quantity
                    :milli-quantity="a.milliQuantity"
                    :unit="a.article.unit"
                    unit-abbreviated
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </template>

        <template v-if="step.tools.length > 0">
          <h2>{{ t('entity.step.field.tools') }}</h2>
          <v-expansion-panels variant="accordion" elevation="1">
            <v-expansion-panel
              v-for="t in step.tools"
              :key="t.id"
              :readonly="!t.description && t.imageUploads.length == 0"
            >
              <v-expansion-panel-title>
                <template #default="{ expanded }">
                  <p :class="{ 'font-weight-bold': expanded }">{{ t.name }}</p>
                </template>
                <template v-if="!t.description && t.imageUploads.length == 0" #actions />
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p class="mb-5">{{ t.description }}</p>

                <v-row>
                  <v-col v-for="u in t.imageUploads" :key="u.id" cols="6">
                    <v-img
                      :src="u.imageUrl"
                      :max-width="imageOptions.width"
                      :max-height="imageOptions.height"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>

        <template v-if="!!step.instructionText">
          <h2>{{ t('entity.step.field.instructionText') }}</h2>
          <markdown :text="step.instructionText" />
        </template>
      </v-col>

      <v-col cols="6">
        <step-uploads-viewer :step-id="stepId" :max-image-height="500" :max-image-width="500" />
      </v-col>
    </v-row>
  </template>
</template>

<style scoped lang="scss">
h2 {
  margin-top: 1.5em;

  &:first-of-type {
    margin-top: 0;
  }
}
thead {
  font-weight: bold;
}
</style>
