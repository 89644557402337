<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  disableReason?: string
}>()
const emit = defineEmits<{
  click: []
}>()

const { t } = useI18n()
const disabled = computed(() => !!props.disableReason)
const onCLick = () => {
  if (disabled.value) {
    return
  }

  emit('click')
}
</script>

<template>
  <v-btn
    density="compact"
    variant="text"
    icon="delete"
    :color="disabled ? `grey` : undefined"
    :style="disabled ? 'cursor: not-allowed' : undefined"
    :title="disabled ? props.disableReason : t('button.delete')"
    @click.stop="onCLick"
  />
</template>

<style scoped lang="scss"></style>
