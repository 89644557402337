<script setup lang="ts">
import { nextTick, ref } from 'vue'
import UpdateImageStepUpload from '@/components/stepUpload/UpdateImageStepUpload.vue'
import { StepUpload } from '@/generated/graphql'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpdateVideoStepUpload from '@/components/stepUpload/UpdateVideoStepUpload.vue'

type StepUploadType = StepUpload['__typename']

const id = ref<string>()
const type = ref<StepUploadType>()
const imageDialog = ref<ComponentExposed<typeof UpdateImageStepUpload>>()
const videoDialog = ref<ComponentExposed<typeof UpdateVideoStepUpload>>()
const open = async (_id: string, _type: StepUploadType) => {
  id.value = _id
  type.value = _type

  nextTick(() => {
    switch (_type) {
      case 'ImageStepUpload':
        return imageDialog.value?.open()
      case 'VideoStepUpload':
        return videoDialog.value?.open()
    }
  })
}

defineExpose({ open })
</script>

<template>
  <template v-if="!!id">
    <update-image-step-upload
      v-if="type == 'ImageStepUpload'"
      v-bind="$attrs"
      :id="id"
      ref="imageDialog"
    />
    <update-video-step-upload
      v-if="type == 'VideoStepUpload'"
      v-bind="$attrs"
      :id="id"
      ref="videoDialog"
    />
  </template>
</template>

<style scoped lang="scss"></style>
