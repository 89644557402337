import { computed, Ref, ref, unref } from 'vue'
import { scrollToFirstInvalidField } from '@/validation'
import { Validation } from '@vuelidate/core'

export default function (
  onSave: () => Promise<unknown>,
  validation?: Validation | Ref<Validation>,
) {
  const showSavedNotification = ref(false)
  const isLoading = ref(false)
  const onSubmit = async () => {
    const realValidation = validation ? unref(validation) : undefined
    if (realValidation) {
      realValidation.$touch()
      if (realValidation.$invalid) {
        scrollToFirstInvalidField()
        return
      }
    }

    isLoading.value = true
    await onSave()
      .then(() => {
        showSavedNotification.value = true
        setTimeout(() => {
          showSavedNotification.value = false
        }, 2_000)
      })
      .finally(() => (isLoading.value = false))
  }
  const saveButtonProps = computed(() => ({
    onClick: onSubmit,
    loading: isLoading.value,
  }))

  return {
    showSavedNotification,
    saveButtonProps,
  }
}
