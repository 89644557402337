<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import UploadPicker, { PickedUpload } from '@/components/upload/UploadPicker.vue'
import CreateStepUpload from '@/components/stepUpload/CreateStepUpload.vue'
import useContentTypes from '@/composables/useContentTypes'

const props = defineProps<{
  stepId: string
}>()
const emit = defineEmits<{
  created: []
}>()

const { t } = useI18n()

const uploadsPickerOpen = ref(false)
const selectedUploads = ref<PickedUpload[]>([])
const tabsModel = ref<string>()
watch(selectedUploads, (v) => {
  if (v.length == 0) {
    return
  }

  tabsModel.value = v[0].id
})

const open = () => {
  uploadsPickerOpen.value = true
}

const onCreated = (upload: PickedUpload) => {
  selectedUploads.value = selectedUploads.value.filter((u) => u.id != upload.id)
  emit('created')
}

const { contentTypesImage, contentTypesVideoForBrowser } = useContentTypes()
const contentTypes = [...contentTypesImage, ...contentTypesVideoForBrowser]

defineExpose({ open })
</script>

<template>
  <upload-picker
    v-model="uploadsPickerOpen"
    :mime-types="contentTypes"
    @pick-multiple="(v) => (selectedUploads = v)"
  />
  <v-dialog
    :model-value="selectedUploads.length > 0"
    scrollable
    max-width="650"
    @update:model-value="selectedUploads = []"
  >
    <v-card>
      <v-card-title>{{ t('component.createStepUploads.title') }}</v-card-title>
      <v-card-text>
        <v-tabs v-model="tabsModel">
          <v-tab v-for="u in selectedUploads" :key="u.id" :value="u.id">
            {{ u.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-window v-model="tabsModel">
          <v-tabs-window-item v-for="u in selectedUploads" :key="u.id" :value="u.id">
            <create-step-upload :step-id="props.stepId" :upload="u" @created="onCreated(u)" />
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="selectedUploads = []">
          {{ t('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
