<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Graph, instance, RenderOptions } from '@viz-js/viz'
import { ModuleNode } from '@/generated/graphql'
import { computed, onUnmounted, ref, watch } from 'vue'
import createPanZoom, { PanZoom } from 'panzoom'
import { v4 } from 'uuid'
import { escapeHtml } from 'markdown-it/lib/common/utils'
import { useTheme } from 'vuetify'

type Node = ModuleNode
const props = defineProps<{
  nodes: Node[]
  nodeText: (node: Node) => string
  createNode: (nodeId: string, parentIds: string[]) => void
  isEditable: boolean
}>()
const emit = defineEmits<{
  nodes: [value: Node[]]
}>()

const { t } = useI18n()

const themeColors = useTheme().computedThemes.value.customLightTheme.colors
const graph = computed<Graph>(() => {
  const explicitModuleDependencies = props.nodes.flatMap((n) =>
    n.parentIds.map((p) => ({
      tail: p,
      head: n.id,
      attributes: {
        color: themeColors['primary'],
        class: 'dag-edge-module',
        tooltip: ' ',
        arrowsize: 1.3,
      },
    })),
  )
  const implicitModuleDependencies = props.nodes.flatMap((n) =>
    n.module.inputs.map((i) => {
      const outputNode = props.nodes.find((n1) => n1.module.id == i.output.module.id) as Node
      const art = i.output.article
      const unit = t(`entity.article.unit.abbreviated.${art.unit}`)
      const tooltip = `${i.milliQuantity / 1000} ${unit} ${art.articleNumber}/${art.revision}`
      return {
        head: n.id,
        tail: outputNode.id,
        attributes: {
          color: themeColors['secondary'],
          class: 'dag-edge-input',
          tooltip,
          constraint: false,
          arrowsize: 1.3,
        },
      }
    }),
  )
  const edges = explicitModuleDependencies.concat(implicitModuleDependencies)

  return {
    directed: true,
    graphAttributes: {
      nodesep: 0.3,
      ordering: 'out',
      rankdir: 'LR',
      remincross: false,
    },
    nodes: props.nodes.map((n) => ({
      name: n.id,
      attributes: {
        id: `dag-node-${n.id}`,
        label: { html: escapeHtml(props.nodeText(n)) },
        tooltip: { html: escapeHtml(props.nodeText(n)) },
        class: 'dag-node',
        shape: 'box',
        margin: '0.25,0.07',
        fontsize: '18', // Hack to create some extra space since VizJS does not know how to calculate text width with Roboto
      },
    })),
    edges,
  }
})
const renderOptions: RenderOptions = {
  engine: 'dot',
}

const dagGraphContainer = ref<HTMLDivElement>()
const svg = ref<SVGElement>()
watch(
  [dagGraphContainer, graph],
  () => {
    if (!dagGraphContainer.value || props.nodes.length == 0) {
      return
    }

    if (svg.value) {
      dagGraphContainer.value?.removeChild(svg.value)
    }

    instance().then((viz) => {
      svg.value = viz.renderSVGElement(graph.value, renderOptions)
      dagGraphContainer.value?.appendChild(svg.value)
    })
  },
  { immediate: true },
)

const panzoom = ref<PanZoom>()
watch(svg, (v) => {
  if (!v) {
    return
  }
  panzoom.value = createPanZoom(v, {
    bounds: true,
    boundsPadding: 0.3,
    initialZoom: 0.9,
  })
})
onUnmounted(() => {
  panzoom.value?.dispose()
})

const nodeIdsWithRandomnessSoMenusRerender = computed(() => {
  return props.nodes.map((n) => ({
    id: n.id,
    node: n,
    random: `${v4() + svg.value?.children.length}`,
  }))
})

function possibleParents(node: Node): Node[] {
  const parentIds = node.parentIds
  const siblingIds = props.nodes
    .filter((n) => n.parentIds.some((p) => parentIds.includes(p)))
    .map((n) => n.id)
  const childIds: string[] = []
  cascadingChildIds(childIds, node)

  return props.nodes
    .filter((n) => n.id != node.id)
    .filter((n) => !parentIds.includes(n.id))
    .filter((n) => !siblingIds.includes(n.id))
    .filter((n) => !childIds.includes(n.id))
}
function cascadingChildIds(ids: string[], node: Node) {
  props.nodes
    .filter((n) => n.parentIds.includes(node.id))
    .forEach((n) => {
      ids.push(n.id)
      cascadingChildIds(ids, n)
    })
}

function addParent(node: Node, parentId: string) {
  node.parentIds.push(parentId)
  emit('nodes', props.nodes)
}

function currentParents(node: Node): Node[] {
  const parentIds = node.parentIds
  return props.nodes.filter((n) => parentIds?.includes(n.id))
}

function deleteParentFromChild(node: Node, parentId: string) {
  node.parentIds = node.parentIds.filter((p) => p != parentId)
  emit('nodes', props.nodes)
}

function possibleNodesAbove(node: Node): Node[] {
  const firstParent = props.nodes.find((n1) => node.parentIds.includes(n1.id))
  if (firstParent) {
    return props.nodes
      .filter((n1) => n1.parentIds.includes(firstParent.id))
      .filter((n) => n.id != node.id)
  }

  return props.nodes.filter((n) => n.parentIds.length == 0).filter((n) => n.id != node.id)
}

function moveNodeAbove(moveThisNode: Node, aboveThatNode: Node) {
  const currentIndex = props.nodes.indexOf(moveThisNode)
  const targetIndex = props.nodes.indexOf(aboveThatNode)
  if (currentIndex == targetIndex) {
    return
  }

  const nodes = props.nodes
  nodes.splice(targetIndex, 0, nodes.splice(currentIndex, 1)[0])

  emit('nodes', nodes)
}
</script>

<template>
  <div ref="dagGraphContainer" class="dagGraphContainer" />

  <v-menu
    v-for="n in nodeIdsWithRandomnessSoMenusRerender"
    :key="n.random"
    :activator="`#dag-node-${n.id}`"
  >
    <v-list>
      <v-list>
        <slot name="menu-items-prepend" :node="n.node" />
        <template v-if="isEditable">
          <v-list-item @click="props.createNode(v4(), [n.id])">
            <v-list-item-title>
              {{ t('view.organization.bopProcess.menuAddChild') }}
            </v-list-item-title>
          </v-list-item>

          <v-menu rounded location="start">
            <template #activator="{ props: activatorProps }">
              <v-list-item v-bind="activatorProps" :disabled="possibleParents(n.node).length == 0">
                {{ t('view.organization.bopProcess.menuAddParent') }}
              </v-list-item>
            </template>

            <v-card>
              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="p in possibleParents(n.node)"
                    :key="`${n.id}-${p.id}`"
                    @click="addParent(n.node, p.id)"
                  >
                    {{ props.nodeText(p) }}
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>

          <v-menu rounded location="start">
            <template #activator="{ props: activatorProps }">
              <v-list-item v-bind="activatorProps" :disabled="currentParents(n.node).length == 0">
                {{ t('view.organization.bopProcess.menuDeleteParent') }}
              </v-list-item>
            </template>

            <v-card>
              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="p in currentParents(n.node)"
                    :key="`${n.id}-${p.id}`"
                    @click="deleteParentFromChild(n.node, p.id)"
                  >
                    {{ props.nodeText(p) }}
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>

          <v-menu rounded location="start">
            <template #activator="{ props: activatorProps }">
              <v-list-item
                v-bind="activatorProps"
                :disabled="possibleNodesAbove(n.node).length == 0"
              >
                {{ t('view.organization.bopProcess.menuMoveAbove') }}
              </v-list-item>
            </template>

            <v-card>
              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="a in possibleNodesAbove(n.node)"
                    :key="`${n.id}-${a.id}`"
                    @click="moveNodeAbove(n.node, a)"
                  >
                    {{ props.nodeText(a) }}
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <slot name="menu-items-append" :node="n.node" />
      </v-list>
    </v-list>
  </v-menu>
</template>

<style lang="scss">
.dagGraphContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  g.dag-edge-input {
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }

    a {
      path {
        stroke-width: 2px;
      }
    }
  }

  g.dag-edge-module {
    path {
      stroke-width: 2px;
    }
  }

  g.dag-node {
    cursor: pointer;

    polygon {
      stroke: #000;
      stroke-width: 1px;
      fill: rgb(var(--v-theme-background));
      filter: drop-shadow(5px 3px 3px rgb(0 0 0 / 0.3));
    }

    text {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
    }
  }
}
</style>
