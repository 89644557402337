const contentTypesImage = ['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml', 'image/avif']
const contentTypesVideoForBrowser = [
  'video/mp4',
  'video/h264',
  'video/webm',
  'video/AV1',
  'video/VP8',
  'video/VP9',
]

export enum ContentTypeGroup {
  IMAGE,
  VIDEO_FOR_BROWSER,
  UNKNOWN,
}
const getContentTypeGroup = (contentType: string) => {
  if (contentTypesImage.includes(contentType)) {
    return ContentTypeGroup.IMAGE
  }
  if (contentTypesVideoForBrowser.includes(contentType)) {
    return ContentTypeGroup.VIDEO_FOR_BROWSER
  }

  return ContentTypeGroup.UNKNOWN
}

export default function () {
  return {
    contentTypesImage,
    contentTypesVideoForBrowser,
    getContentTypeGroup,
  }
}
